<template>
	<div class="tour_head fixed-top">
		<h3>현 위치 주변 장소</h3>
	</div>
	<div id="map" class="container-fluid">
		<div class="row">
			<!-- 지도 및 동영상 플레이어 -->
			<div class="col-md-12">
				<div id="googleMap" class="map" style="height: 300px">
					<!-- 지도 내용 -->
				</div>
			</div>
		</div>
	</div>
	<div
		class="tour_list d-flex flex-wrap gap-2"
		style="overflow-y: scroll; max-height: calc(100vh - 300px)"
	>
		<div
			v-for="(spotPlace, index) in googleSpotPlaceList"
			:key="spotPlace.spId"
			class="tour_item col-md-6 col-6"
		>
			<p class="photo">
				<img
					:src="`https://storage.googleapis.com/allthestreet-images/spot_images/normal/${spotPlace.googleSpotImage.filePath}`"
					@click="showSpotDetail(spotPlace)"
				/>
			</p>
			<div class="tour_info">
				<p class="tour_tit">
					<img
						:src="`/images/marker/${index + 1}.png`"
						alt="Icon"
						class="icon-image"
						width="20"
						height="20"
					/>&nbsp;&nbsp;{{ spotPlace.spName }}
				</p>

				<div class="starList">
					<strong>{{ spotPlace.spRating }}</strong
					><span
						><i
							v-for="n in Math.floor(spotPlace.spRating)"
							:key="n"
							class="bi bi-star-fill text-warning"
							style="margin-left: 5px"
						></i>
						<i
							v-for="n in 5 - Math.ceil(spotPlace.spRating)"
							:key="n"
							class="bi bi-star text-warning"
							style="margin-left: 5px"
						></i
					></span>
					<span>({{ spotPlace.spReviewCnt }})</span>
				</div>
			</div>
		</div>
	</div>
	<p class="text-center">
		<a href="https://forms.gle/dgSWRrJsLrASXcoh9" class="btn-primary"
			>서비스 만족도 조사
		</a>
	</p>
	<GoApp v-if="isGoAppVisible" @close="closeGoApp"></GoApp>
	<SpotDetail
		v-if="isSpotDetailVisible"
		:spot="spotDetailData"
		@close="closeSpotDetail"
	></SpotDetail>
</template>

<script>
import axios from 'axios';
import GoApp from '@/components/GoApp.vue';
import SpotDetail from '@/components/SpotDetail.vue';

export default {
	name: 'QrScan',
	components: { GoApp, SpotDetail },
	data() {
		return {
			isGoAppVisible: false, //  첫 페이지 모달 띄우는 Flag
			isSpotDetailVisible: false, // 스팟 상세정보 창 띄우기 Flag
			spotDetailData: null, // 1개 스팟 상세 정보를 담은 object, SpotDetail component에서 사용
			mapData: {
				isExecuted: 'n',
				mapIdName: 'googleMap',
				notWalkedPathArray: [],
				walkedPathArray: [],
			},
			gStartLat: 0, // 지도 출발 위도 위치값(프로그램 시작 시 입력값으로 받음 )
			gStartLng: 0, // 지도 출발 경도 위치값(프로그램 시작 시 입력값으로 받음 )
			gRadius: 0.1,
			googleSpotPlaceList: [],
			google: null,
			emailId: '',
		};
	},

	mounted() {
		import('@/main').then((mainModule) => {
			mainModule.loadedGoogleMapsAPI.then(() => {
				this.initMap();
			});
		});
	},

	created() {
		this.showGoApp();
	},

	methods: {
		/* eslint-disable */
		async initMap() {
			if (typeof google === 'object' && typeof google.maps === 'object') {
				// URL 파라미터에서 값을 추출
				const params = new URLSearchParams(window.location.search);
				this.gStartLat = Number(params.get('startLat'));
				this.gStartLng = Number(params.get('startLng'));
				const radiusParam = params.get('radius');
				this.gRadius = radiusParam ? Number(radiusParam) : 0.1;
				await this.updateSpotPlaceList(this.gStartLat, this.gStartLng, this.gRadius);
				this.drawMapDirect(); // 구글맵 그리기
				this.drawMyWalkerMarker(this.gStartLat, this.gStartLng); // 현 위도 경도에 아바타 넣기
			}
		},

		// review 및 spot 추가 정보를 가져올때 사용
		async updateSpotPlaceList(lat, lng, radius) {
			const youtubeAreaRoute = {
				lat: lat.toString(),
				lng: lng.toString(),
				regionId: Number(this.regionId),
				loginId: this.emailId,
				radius: Number(radius),
			};
			await axios
				.post(
					'/api/v1/getGoogleSpotPlaceListByRegionPositionWithRadius',
					youtubeAreaRoute,
				)
				.then((response) => {
					if (response.data) {
						this.googleSpotPlaceList = response.data;
					}
				})
				.catch((error) => {
					alert(`[updateSpotPlaceList][error][${error}]`);
				});
		},

		drawMapDirect() {
			const lat = this.gStartLat;
			const lng = this.gStartLng;
			const mapOpt = {
				center: { lat, lng },
				zoom: 18,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				disableDefaultUI: true,
				zoomControl: true,
			};
			this.mapData.mapId = new google.maps.Map(
				document.getElementById(this.mapData.mapIdName),
				mapOpt,
			);
			if (!this.isEmpty(this.googleSpotPlaceList)) {
				// 해당 지역의 spotMarker 생성
				this.googleSpotPlaceList.forEach((googleSpotPlace, index) => {
					this.drawGoogleSpotPlaceAroundRegion(googleSpotPlace, index + 1);
				});
			}
		},

		drawGoogleSpotPlaceAroundRegion(googleSpotPlace, index) {
			const markerImage = {
				url: '/images/marker/' + index + '.png', // 이미지 URL을 여기에 입력
				scaledSize: new google.maps.Size(30, 30), // 이미지 크기 조절
			};
			// Google 지도 마커 생성
			const mapId = this.mapData.mapId;
			const googleSpotMarker = new google.maps.Marker({
				position: {
					lat: Number(googleSpotPlace.lat),
					lng: Number(googleSpotPlace.lng),
				},
				map: mapId, // Google 지도 객체
				icon: markerImage,
				zIndex: 1000,
			});
		},

		drawMyWalkerMarker(lat, lng) {
			const image = {
				url: 'images/yeosu_logo.png', // 이미지 URL
				scaledSize: new google.maps.Size(50, 50), // 크기 지정 (픽셀 단위)
			};
			// Create an info window.
			const myWalkerMarker = new google.maps.Marker({
				position: { lat, lng },
				map: this.mapData.mapId,
				draggable: false,
				icon: image,
			});
		},

		isEmpty(value) {
			return value == null || value.length === 0;
		},
		showGoApp() {
			this.isGoAppVisible = true;
		},
		closeGoApp() {
			this.isGoAppVisible = false;
		},
		showSpotDetail(spotPlace) {
			this.spotDetailData = spotPlace;
			this.isSpotDetailVisible = true;
		},
		closeSpotDetail() {
			this.isSpotDetailVisible = false;
		},
	},
};
</script>

<style scoped>
.tour_head {
	background: var(--bs-white);
	width: 100%;
	text-align: center;
	padding: 0.8rem;
	z-index: 10;
}
.tour_head h3 {
	color: var(--bs-primary);
	font-size: 1.2rem;
	padding: 0;
	margin: 0;
}
.detail_map {
	width: 100%;
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
}
.detail_map iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.tour_list {
	padding: 1rem;
}
.tour_item.col-6 {
	width: calc(50% - 0.25em);
}
.tour_item .photo {
	border-radius: 0.5rem;
	overflow: hidden;
	margin-bottom: 0.4rem;
}
.tour_item .photo img {
	width: 100%;
	height: 100px;
}
.tour_item .tour_tit {
	font-weight: 600;
	margin-bottom: 0.2rem;
	color: var(--bs-black);
	font-size: 0.95rem;
}
.tour_item .starList {
	vertical-align: middle;
	line-height: 1em;
}
.tour_item .starList strong {
	font-size: 0.9rem;
	display: inline-block;
	vertical-align: middle;
}
.tour_item .starList span {
	display: inline-block;
	font-size: 0.85rem;
	color: #888;
	vertical-align: middle;
	margin-left: 0.4rem;
}
.tour_item .video_tag {
	margin-bottom: 0;
}
.tour_item .video_tag span {
	display: inline-block;
	font-size: 0.85rem;
	color: #888;
}
.tour_title {
	position: relative;
	background: var(--bs-primary);
	padding: 0.8rem;
}
.tour_title h3 {
	color: var(--bs-white);
	font-size: 1.2rem;
	padding: 0;
	margin: 0;
}
.tour_title .close {
	background: rgba(0, 0, 0, 0);
	border: none;
	color: var(--bs-white);
	position: absolute;
	top: 0.8rem;
	right: 0.8rem;
}
.tour_detail_body {
	padding: 1rem;
}
.tour_place_area {
	overflow: hidden;
}
.tour_place_area img {
	width: 100%;
}
</style>
