import { createRouter, createWebHistory } from 'vue-router';
import PlayYouTube from './views/PlayYouTube.vue';
import PlayYouTubePc from './views/PlayYouTubePc.vue';
import QrScan from './views/QrScan.vue';
import QrList from './views/QrList.vue';
import YouTubeList from './views/YouTubeList.vue';
import YouTubeListSlide from './views/YouTubeListSlide.vue';
import YouTubeListSlideIndex from './views/YouTubeListSlideIndex.vue';
import RewardProgram from './views/RewardProgram.vue';
import YouTuberPage from './views/YouTuberPage.vue';
import YouTubeListOrigin from './views/YouTubeListOrigin.vue';
import YouTubeListSlideOrigin from './views/YouTubeListSlideOrigin.vue';

const routes = [
	{
		path: '/',
		name: 'index',
		component: YouTubeList,
		props: true,
	},
	{
		path: '/playyoutube',
		name: 'PlayYouTube',
		component: PlayYouTube,
		props: true,
	},
	{
		path: '/playyoutubepc',
		name: 'PlayYouTubePc',
		component: PlayYouTubePc,
		props: true,
	},
	{
		path: '/qrscan',
		name: 'QrScan',
		component: QrScan,
	},
	{
		path: '/qrlist',
		name: 'QrList',
		component: QrList,
	},
	{
		path: '/youtubelist',
		name: 'YouTubeList',
		component: YouTubeList,
	},
	{
		path: '/youtubelistslide',
		name: 'YouTubeListSlide',
		component: YouTubeListSlide,
	},
	{
		path: '/youtubelistslideindex',
		name: 'YouTubeListSlideIndex',
		component: YouTubeListSlideIndex,
	},
	{
		path: '/youtubelistorigin',
		name: 'YouTubeListOrigin',
		component: YouTubeListOrigin,
	},
	{
		path: '/youtubelistslideorigin',
		name: 'YouTubeListSlideOrigin',
		component: YouTubeListSlideOrigin,
	},
	{
		path: '/rewardprogram',
		name: 'RewardProgam',
		component: RewardProgram,
	},
	{
		path: '/youtuberpage',
		name: 'YouTuberPage',
		component: YouTuberPage,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
