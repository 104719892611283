<template>
	<div class="stVideoVisual">
		<!-- stVideoVisual 1 section -->
		<div class="stVideoVisual_item">
			<div class="container flex-wrap gap-3">
				<div class="row">
					<div v-for="(qr, index) in qrlist" :key="index" class="col-sm-12 col-md-4">
						<div class="box">
							<a :href="`${qr.url}`">
								<div class="thum">
									<p class="videothum">
										<img
											onerror="this.onerror=null; this.style.display = 'none'"
											:src="`${qr.img}`"
											class="card-img-top"
											alt="Video Thumbnail"
										/>
									</p>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'QrList',
	components: {},
	data() {
		return {
			qrlist: [
				{
					url: 'https://m.site.naver.com/1eXh8',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_15/1698373696289v8MHR_PNG/1eXh8.png',
				},
				{
					url: 'https://m.site.naver.com/1eXnl',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_92/1698373665252bzFcT_PNG/1eXnl.png',
				},
				{
					url: 'https://m.site.naver.com/1eXnx',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_227/1698373644151bvta2_PNG/1eXnx.png',
				},
				{
					url: 'https://m.site.naver.com/1eXnD',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_1/1698373609664Dt8uO_PNG/1eXnD.png',
				},
				{
					url: 'https://m.site.naver.com/1eXnP',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_82/1698373337980ge2Tu_PNG/1eXnP.png',
				},
				{
					url: 'https://m.site.naver.com/1eXo8',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_180/1698373367235e19QB_PNG/1eXo8.png',
				},
				{
					url: 'https://m.site.naver.com/1eXof',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_103/1698373393072wz6V2_PNG/1eXof.png',
				},
				{
					url: 'https://m.site.naver.com/1eXop',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_59/1698373419612xQTGl_PNG/1eXop.png',
				},
				{
					url: 'https://m.site.naver.com/1eXoy',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_152/1698373457782x5z8Y_PNG/1eXoy.png',
				},
				{
					url: 'https://m.site.naver.com/1eXpi',
					img: 'https://qrcodethumb-phinf.pstatic.net/20231027_293/1698373797509SIYk0_PNG/1eXpi.png',
				},
			],
		};
	},

	mounted() {},

	created() {},

	methods: {},
};
</script>

<style scoped></style>
