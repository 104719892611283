<template>
	<div class="stVideoVisual">
		<!-- stVideoVisual 1 section -->
		<div class="stVideoVisual_item">
			<div class="container flex-wrap gap-3">
				<div class="row">
					<div
						v-for="(youtube, index) in youtubeAreaInfoList"
						:key="index"
						class="col-sm-12 col-md-4"
					>
						<div class="box">
							<!-- <a
								:href="
									isMobile
										? `/playyoutube?type=1&areaId=${youtube.areaId}&regionId=12&url=${youtube.url}`
										: `/playyoutubepc?type=1&areaId=${youtube.areaId}&regionId=12&url=${youtube.url}`
								"
							> -->
							<a
								:href="
									isMobile
										? `https://yeosu.go.kr/tour/leisure/experience/youtube_play?framesrc=https://yeosu.allthestreet.kr/playyoutube?type=1&areaId=${youtube.areaId}&regionId=12&url=${youtube.url}`
										: `https://yeosu.go.kr/tour/leisure/experience/youtube_play?framesrc=https://yeosu.allthestreet.kr/playyoutubepc?type=1&areaId=${youtube.areaId}&regionId=12&url=${youtube.url}`
								"
								@click.prevent="handleClick"
							>
								<div class="thum">
									<div class="moreVideoBTN">
										<div class="ico">
											<img src="../assets/images/icon-youtube-w.svg" />
										</div>
										<div class="text">이 동영상 시청</div>
									</div>
									<p class="videothum">
										<img
											onerror="this.onerror=null; this.style.display = 'none'"
											:src="
												'https://img.youtube.com/vi/' +
												youtube.url +
												'/mqdefault.jpg'
											"
											class="card-img-top"
											alt="Video Thumbnail"
										/>
									</p>
								</div>
							</a>
							<div class="textArea">
								<div class="subject">
									{{ youtube.title }}
								</div>
								<div class="iconArea d-flex align-items-center">
									<div class="youtubeChannel">
										<p class="youtube_thum">
											<img
												:src="
													youtube.profileImageUrl || '/images/youtubeIco.png'
												"
												class="rounded-image"
												alt="Profile Image"
											/>
										</p>
										<a
											:href="
												'https://www.youtube.com/channel/' + youtube.channelId
											"
											target="_blank"
											><span>{{ youtube.channelTitle }}</span></a
										>
									</div>
									<div class="date">
										{{ youtube.youtubeMakeDat.substring(0, 10) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<p class="mt-5 text-center">
		<a href="/rewardprogram" class="btn-primary">콘텐츠 공유 리워드 프로그램 </a>
	</p>
</template>

<script>
import axios from 'axios';

export default {
	name: 'YouTubeList',
	components: {},
	data() {
		return {
			connectInfo: {},
			regionMap: null,
			YOUTUBE_VIEW_CNT: 6,
			pageInfoList: [],
			pageInfo: {
				visibleNo: 1,
				totalPageCnt: 0,
				currentPage: 0,
				dataCntPerPage: this.YOUTUBE_VIEW_CNT,
			},
			youtubeAreaInfoList: [],
			gotRegionList: [], // 조회한 regionName list
			mergelist: [], // 검색을 위한 등록된 모든 지역 데이타(region, 분기점, spotPlace 등) list
			regionList: [], // 등록된 region 데이타 list
			hashTagList: [], // 등록된 hashTag list
			searchAreaData: {},
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			),
		};
	},

	mounted() {},

	created() {
		//this.getHashTagListFromServer();
		this.procSelectedRegionYoutubeAreaDataList();
		// console.log(this.isMobile);
	},

	methods: {
		handleClick(event) {
			const href = event.currentTarget.getAttribute('href');
			window.parent.location.href = href;
			event.preventDefault();
			// console.log(href);
		},
		/* eslint-disable */
		async getHashTagListFromServer() {
			try {
				const response = await axios.get('/api/v1/getHashTagList');

				if (response.data) {
					this.hashTagList = response.data;
					// console.log(this.hashTagList);
				}
			} catch (error) {
				alert('[getHashTagListFromServer][error][' + error.response.data + ']');
			}
		},
		async procSelectedRegionYoutubeAreaDataList() {
			try {
				const response = await axios.get('/api/v1/getYoutubeAreaInfoListByAreaName', {
					params: {
						areaName: '이순신광장',
					},
				});
				if (response.data) {
					// console.log(response);
					this.youtubeAreaInfoList = response.data;
				}
			} catch (error) {
				alert('[selectGoogleSpotPlaceList][error]' + error.response.data);
			}
		},
	},
};
</script>

<style scoped>
/* 컴포넌트의 CSS 스타일은 여기에 작성합니다. */
.rounded-image {
	border-radius: 50%;
	width: 30px;
	height: 30px;
}
</style>
