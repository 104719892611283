<template>
	<div class="sub_title_box mat30">
		<div class="sub_title_no">
			<h2 class="con c0 i7 mab20">함께 만드는 여수 관광!!</h2>
			<p class="s_txt">
				갖고 계신 여수 관광 동영상을 공유해 주세요. 조회수 증가와 리워드는 덤~!!
			</p>
		</div>
	</div>
	<div class="guideArea mt-5">
		<div class="guide-sec">
			<div class="guide-flex d-flex gap-3 align-items-center">
				<div class="guide-txt">
					<p class="tit">
						<span>1</span>자신이 촬영한 여수 관광 동영상을 유튜브에 업로드하고
						<br />
						<strong class="text-primary">‘퍼가기 허용’</strong> 설정을 해 주세요.
					</p>
					<p>* 내 채널 > 동영상 선택 > 세부정보 > 자세히보기</p>
					<p class="tit mt-4">
						<span>2</span>아래 공유 URL에 유튜브 동영상 주소의 v= 이하를 입력해
						주세요.
					</p>
					<p>
						예: https://www.youtube.com/watch?v=<strong class="text-danger"
							>a40NsY9ZgCU</strong
						>
					</p>
					<p class="tit mt-4">
						<span>3</span>자신이 누구인지 알려주시고 콘텐츠 활용에 동의해 주세요.
					</p>
					<p>
						* 누적 조회수, 시청시간, 기여도에 따라 등록하신 연락처로 리워드를 안내해
						드립니다.
					</p>
				</div>
				<div class="guide-img">
					<img
						src="../assets/images/img-guide.png"
						alt="유튜브 퍼가기 허용설정해주세요"
					/>
				</div>
			</div>
		</div>
	</div>
	<div class="sub_title_box pt-5 mt-5">
		<div class="sub_title_no">
			<h2 class="con c0 i7 mab20">동영상 공유 등록</h2>
		</div>
	</div>
	<table
		class="board_write"
		summary="이 표는 관광후기 내용 입력란을 제공하며  제목, 등록자,    내용으로 구성되어 있습니다."
	>
		<caption>
			관광후기 입력
		</caption>
		<colgroup>
			<col width="15%" />
			<col width="35%" />
			<col width="15%" />
			<col />
		</colgroup>
		<tbody>
			<tr>
				<th scope="row"><label for="reg_name">이름</label> <em>(*)</em></th>
				<td colspan="3">
					<input
						id="reg_name"
						v-model="reg_name"
						type="text"
						class="w20"
						name="reg_name"
						placeholder="홍길동"
					/>
				</td>
			</tr>
			<tr>
				<th scope="row">
					<label for="phone">휴대폰 번호<em>(*)</em></label>
				</th>
				<td>
					<input
						id="phone"
						v-model="phone"
						type="text"
						name="phone"
						placeholder="010-0000-0000"
					/>
				</td>

				<th scope="row"><label for="birth">생년월일</label> <em>(*)</em></th>
				<td>
					<input
						id="birth"
						v-model="birth"
						type="text"
						name="birth"
						placeholder="예 1999.12.04"
					/>
				</td>
			</tr>
			<tr>
				<th scope="row"><label for="youtube">유튜브 채널명</label> <em>(*)</em></th>
				<td colspan="3">
					<input
						id="youtube"
						v-model="youtube"
						type="text"
						name="youtube"
						placeholder="예 여수사랑"
					/>
				</td>
			</tr>
			<tr>
				<th scope="row">공유 URL<em>(*)</em></th>
				<td colspan="3">
					<!-- v-for를 사용하여 입력 필드를 동적으로 생성 -->
					<a class="btn_add add_list" href="#none" @click="addSharedUrl"
						>+ 공유 URL 추가</a
					>
					<div
						v-for="(url, index) in sharedUrls"
						:key="index"
						class="vertical-input"
					>
						<input v-model="url.value" class="w20" type="text" name="" />
					</div>
				</td>
			</tr>
		</tbody>
	</table>

	<div class="form-check">
		<input id="flexCheckDefault" class="form-check-input" type="checkbox" value="" />
		<label class="form-check-label" for="flexCheckDefault">
			상기 본인은 콘텐츠 활용에 동의합니다.</label
		>
		<a
			href="#"
			class="text-primary text-decoration-underline"
			data-bs-toggle="modal"
			data-bs-target="#UseContentsAgree"
			@click="openModal"
			>콘텐츠 활용 동의 약관 보기</a
		>
	</div>
	<div class="board_button_list">
		<ul>
			<li><a href="/youtubelist">취소</a></li>
			<li>
				<input
					id="v_btn_confirm"
					type="submit"
					value="등록완료"
					class="btn_blue1"
					@click="sendEmail"
				/>
			</li>
		</ul>
	</div>
	<div
		id="UseContentsAgree"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="useContentsAgreeLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h3 id="exampleModalLabel" class="modal-title">
						콘텐츠 활용 동의 약관 보기
					</h3>

					<button
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<h6>
						여수시는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계
						법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게
						관리하고 있습니다.
					</h6>
					<h6>
						여수시의 수탁업체로서 올더스트릿(주)의 동영상 여행 체험 서비스(이하 ‘본
						서비스’)는 사용자가 동영상 및 기타 콘텐츠를 찾아보고, 감상하고,
						공유하며, 전 세계 사람들과 소통하고, 정보를 나누며, 아이디어를 주고 받을
						수 있는 공간을 제공합니다.
					</h6>
					<h6>
						본 서비스를 제공하는 올더스트릿㈜(이하 '수탁기관')는 대한민국 법률에
						따라 설립되었고 운영됩니다.
					</h6>
					<h6>
						본 서비스에서는 귀하의 유튜브 동영상 콘텐츠(이하 ‘동영상’)에 위치정보를
						연동하여 사용자에게 보다 나은 이용 경험을 제공하고 다양한 사업자에게
						새로운 부가가치를 제공하고자 합니다.
					</h6>
					<h6>
						이를 위해, 수탁기관은 귀하에게 귀하의 동영상 활용에 대한 동의를 받고자
						합니다.
					</h6>

					<h5>개인정보수집 항목</h5>
					<h6>
						본 서비스에서 귀하로부터 저작권 활용 동의를 위해 다음의 정보를
						수집합니다.
					</h6>
					<h6>필수: 이름(닉네임), 전화번호, 생년월일, 유튜브 채널명, 공유 URL</h6>
					<h5>개인위치정보 이용 및 제공</h5>
					<h6>
						본 서비스에서 귀하의 동영상을 위치정보와 연동합니다. 이에 대한 결과물인
						2차 저작물은 동영상의 타임라인별 위치정보를 갖으며 동선형태로 본
						서비스의 다른 사용자에게 제공하게 됩니다.
					</h6>
					<h6>
						귀하는 개인위치정보주체로서 수탁기관에 개인위치정보의 이용 또는 제공에
						대한 중단을 요청할 수 있습니다.
					</h6>
					<h5>귀하의 동영상에 대한 저작권 활용 및 운영 동의</h5>
					<h5>귀하가 본 서비스 및 다른 사용자에게 부여하는 권리</h5>
					<h6>
						귀하의 동영상에 대한 소유권 및 저작권은 귀하에게 있습니다. 귀하는 동의를
						통해 본 서비스 및 본 서비스의 다른 사용자에게 아래에 설명된 바와 같이
						일정한 권리를 부여합니다.
					</h6>
					<h5>본 서비스에 부여하는 라이선스</h5>
					<h6>
						귀하는 동의를 통해 귀하의 동영상을 본 서비스가 다른 부가정보를 결합하여
						2차 저작물로 사용(복제, 배포, 수정, 표시, 공연 포함)할 수 있는
						세계적이고, 비독점적이며, 무상으로 제공되고, 양도가능하며,
						서브라이선스를 허여할 수 있는 라이선스를 본 서비스에 부여합니다.
						수탁기관은 본 서비스를 운영하고, 홍보 및 개선하기 위한 목적으로만 이러한
						라이선스를 사용할 수 있습니다. 본 약관의 어떠한 조항도 법령에서 허용되는
						범위를 넘어서는 라이선스를 수탁기관에 부여하는 것은 아닙니다.
					</h6>
					<h5>다른 사용자에게 부여하는 라이선스</h5>
					<h6>
						또한, 귀하는 본 서비스의 다른 사용자들에게 본 서비스를 통해 귀하의
						동영상에 액세스할 수 있는 세계적이고, 비독점적이며, 무상으로 제공되는
						라이선스를 부여하며, 본 서비스에서 제공하는 기능(예를 들어, 북마크,
						공유)을 통해 설정된 바에 따라 해당 동영상을 사용할 권리, 즉, 복제, 배포,
						수정, 표시, 공연 등을 할 수 있는 권리를 부여합니다. 다만, 이 라이선스는
						다른 사용자가 본 서비스와는 별개로 귀하의 동영상을 활용할 수 있는 어떠한
						권리나 권한도 부여하지 않습니다.
					</h6>
					<h5>라이선스 기간</h5>
					<h6>
						귀하가 부여한 라이선스는 해당 동영상이 본 서비스에서 삭제될 때까지
						유지됩니다. 동영상이 삭제되면 라이선스가 종료되지만, 서비스 운영, 삭제
						전에 허용한 동영상의 사용, 또는 법령에서 달리 요구하는 경우에는 예외가
						인정될 수 있습니다. 예를 들어, 귀하가 동영상을 삭제 요청하더라도 본
						서비스 상에서 삭제할 뿐 귀하의 동영상이 포함된 홍보자료를 회수하는 것은
						아닙니다.
					</h6>
					<h5>본 서비스의 동영상 활용</h5>
					<h6>
						본 서비스는 귀하의 동영상에 위치정보를 연동합니다. 이를 통해 동영상의
						타임라인에 따라 동선을 생성하고 주변의 다른 부가정보와 연계하며 여러
						속성에 따라 동영상을 분류합니다. 또한 이러한 결과로써 얻어진 2차
						저작물은 본 서비스의 다른 사용자에게 다양한 방식으로 필요에 맞게
						제공됩니다. 본 서비스는 귀하의 동영상에 대한 조회수를 늘리기 위해
						노력하며 귀하의 유튜브 채널에 접근하거나 유튜브의 구독에 저해되는 어떠한
						방해나 장벽을 세우지 않습니다.
					</h6>
					<h5>수익 창출 권리</h5>
					<h6>
						귀하는 본 서비스에 있는 귀하의 동영상에서 수익을 창출할 권리를 본
						서비스에 부여합니다. 수익 창출에는 광고를 게재하거나 사용자에게 이용료를
						청구하는 것도 포함될 수 있습니다. 본 동의를 통해 수탁기관이 귀하에게
						수익금을 지급하는 것은 아닙니다. 향후 이러한 여건이 갖춰지면 수탁기관은
						귀하와 별도 계약을 통해 수익금 분배(예를 들어, 파트너 프로그램, 채널
						멤버십 등)를 적극 고려할 예정입니다.
					</h6>
					<h5>저작권 보호</h5>
					<h6>
						수탁기관은 저작권자의 지적 재산을 관리하는 데 최선의 노력을 다
						하겠습니다. 본 서비스로 인해 귀하의 저작권이 침해되었다고 생각하는 경우
						contact@allthestreet.com으로 알려 주시기 바랍니다.
					</h6>
					<h6>
						상기 개인정보수집, 개인위치정보 이용 및 제공, 저작권 활용 및 운영 동의에
						귀하가 정보를 입력하고 ‘등록완료’ 버튼을 누름으로써 본 내용에 동의하는
						것으로 간주합니다.
					</h6>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
						닫기
					</button>
				</div>
			</div>
		</div>
	</div>
	<!-- //2023-10-05 내용삽입 -->
</template>

<script>
import emailjs from '@emailjs/browser';
export default {
	name: 'RewardProgram',
	components: {},
	data() {
		return {
			reg_name: '',
			phone: '',
			birth: '',
			youtube: '',
			sharedUrls: [{ id: 1, value: '' }],
		};
	},

	mounted() {},

	created() {},

	methods: {
		addSharedUrl() {
			this.sharedUrls.push({ id: Date.now(), value: '' }); // 새로운 빈 객체 추가
		},
		sendEmail() {
			const message = this.mergeMessage();
			let template_params = {
				message: message,
			};
			emailjs
				.send(
					'service_0t7q222',
					'template_uhunu77',
					template_params,
					'ih9mYtX7wPZwI4qNp',
				)
				.then(
					(result) => {
						console.log('SUCCESS!', result.text);
						// 메일 보내기 성공 시 알림 표시
						alert('동영상 공유등록이 정상적으로 되었습니다.');
						// 특정 URL로 리디렉션
						window.location.href = '/youtubelist'; // 원하는 URL로 대체
					},
					(error) => {
						console.log('FAILED...', error.text);
					},
				);
		},

		mergeMessage() {
			let message = '<table>';

			// 이름
			message +=
				'<tr><td><strong>이름:</strong></td><td>' + this.reg_name + '</td></tr>';

			// 휴대폰 번호
			message +=
				'<tr><td><strong>휴대폰 번호:</strong></td><td>' + this.phone + '</td></tr>';

			// 생년월일
			message +=
				'<tr><td><strong>생년월일:</strong></td><td>' + this.birth + '</td></tr>';

			// 유튜브 채널명
			message +=
				'<tr><td><strong>유튜브 채널명:</strong></td><td>' +
				this.youtube +
				'</td></tr>';

			// 공유 URL
			message += '<tr><td><strong>공유 URL:</strong></td><td>';

			for (let i = 0; i < this.sharedUrls.length; i++) {
				message += this.sharedUrls[i].value + '<br>';
			}

			message += '</td></tr>';

			message += '</table>';

			return message;
		},
	},
};
</script>

<style scoped>
.vertical-input {
	display: flex;
	align-items: center; /* 세로 중앙 정렬 */
	margin-bottom: 10px; /* 필요에 따라 간격 조절 */
}
</style>
