<template>
	<div class="splash_wrap">
		<button class="splash_close" title="닫기">
			<i class="xi-close xi-x" @click="closeGoApp"></i>
		</button>
		<h3>주변 장소 보기</h3>
		<div class="d-flex justify-content-center align-content-center">
			<div class="d-flex justify-content-center">
				<p><img src="../assets/images/app_icon.png" /></p>
				<p @click="goToYeosuApp">여수엔 앱에서<br />보시겠습니까?</p>
			</div>
		</div>
		<p><a href="#" @click="closeGoApp">아니오, 모바일 웹으로 보겠습니다.</a></p>
	</div>
</template>

<script>
export default {
	name: 'GoApp',
	emits: ['close'], // "close" 이벤트를 명시적으로 선언
	methods: {
		closeGoApp() {
			this.$emit('close'); // 부모 컴포넌트에 모달 닫기 이벤트 전달
		},
		goToYeosuApp() {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if (/android/i.test(userAgent)) {
				setTimeout(() => {
					window.location.href =
						'https://play.google.com/store/apps/details?id=kr.smartyeosu.yeosuen&pli=1';
				}, 500);
			} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				setTimeout(() => {
					window.location.href =
						'https://apps.apple.com/kr/app/%EC%97%AC%EC%88%98%EC%97%94/id6444842728';
				}, 500);
			}
		},
	},
};
</script>

<style scoped>
/* 모달 스타일 */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #6f6363;
	display: flex;
	justify-content: center;
	align-items: center;
}

body,
input,
textarea,
select,
button {
	font-family: 'Noto Sans KR', 'Malgun Gothic', 'Dotum', '돋움', 'Arial';
	line-height: 1.5em;
	color: #323232;
	letter-spacing: -0.05em;
}
.splash_wrap {
	position: fixed;
	z-index: 100;
	top: 0;
	height: 100vh;
	width: 100vw;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
}
.splash_wrap > .d-flex {
	align-items: center;
	height: 100vh;
}
.splash_wrap .d-flex > .d-flex {
	background: #f8f8f8;
	border-radius: 0.4rem;
	overflow: hidden;
	align-items: center;
	padding: 0.1rem 4rem 0.1rem 1rem;
}
.splash_wrap .d-flex > .d-flex p {
	margin: 0;
	padding: 0;
	color: #004b7c;
	font-weight: 600;
	line-height: 1.2em;
}
.splash_wrap .d-flex > .d-flex p:first-child {
	margin-right: 0.5rem;
	text-align: center;
}
.splash_wrap .d-flex > .d-flex p img {
	width: 90%;
}
.splash_wrap .splash_close {
	background: rgba(0, 0, 0, 0);
	border: none;
	color: var(--bs-white);
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.splash_wrap > p {
	margin: 0;
	padding: 0;
	text-align: center;
	position: absolute;
	bottom: 20%;
	left: 0;
	width: 100%;
}
.splash_wrap > p a {
	color: var(--bs-white);
	text-decoration: underline;
}
.splash_wrap > h3 {
	color: var(--bs-white);
	margin: 0;
	padding: 0;
	text-align: center;
	position: absolute;
	top: 20%;
	left: 0;
	width: 100%;
	font-size: 1.9rem;
}
</style>
