<template>
	<carousel v-bind="settings" :breakpoints="breakpoints">
		<!-- stVideoVisual 1 section -->
		<slide v-for="(youtube, index) in youtubeAreaInfoList" :key="index">
			<a
				:href="
					isMobile
						? `https://yeosu.go.kr/tour/leisure/experience/youtube_play?framesrc=https://yeosu.allthestreet.kr/playyoutube?type=1&areaId=${youtube.areaId}&regionId=12&url=${youtube.url}`
						: `https://yeosu.go.kr/tour/leisure/experience/youtube_play?framesrc=https://yeosu.allthestreet.kr/playyoutubepc?type=1&areaId=${youtube.areaId}&regionId=12&url=${youtube.url}`
				"
				@click.prevent="handleClick"
			>
				<div class="thum">
					<div class="moreVideoBTN">
						<div class="ico">
							<img src="../assets/images/icon-youtube-w.svg" />
						</div>
						<div class="text">이 동영상 시청</div>
					</div>
					<p class="videothum">
						<img
							onerror="this.onerror=null; this.style.display = 'none'"
							:src="'https://img.youtube.com/vi/' + youtube.url + '/mqdefault.jpg'"
							class="card-img-top"
							alt="Video Thumbnail"
							style="max-width: 282px"
						/>
					</p>
				</div>
			</a>
		</slide>
	</carousel>
</template>

<script>
import axios from 'axios';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
	name: 'YouTubeListSlide',
	components: {
		Carousel,
		Slide,
	},
	data() {
		return {
			settings: {
				autoplay: 3000,
				wrapAround: true,
				itemsToShow: 1,
				snapAlign: 'center',
			},
			breakpoints: {
				// 700px and up
				700: {
					itemsToShow: 2,
					snapAlign: 'start',
				},
				// 1024 and up
				1024: {
					itemsToShow: 4,
					snapAlign: 'start',
				},
			},
			connectInfo: {},
			regionMap: null,
			YOUTUBE_VIEW_CNT: 6,
			pageInfoList: [],
			pageInfo: {
				visibleNo: 1,
				totalPageCnt: 0,
				currentPage: 0,
				dataCntPerPage: this.YOUTUBE_VIEW_CNT,
			},
			youtubeAreaInfoList: [],
			gotRegionList: [], // 조회한 regionName list
			mergelist: [], // 검색을 위한 등록된 모든 지역 데이타(region, 분기점, spotPlace 등) list
			regionList: [], // 등록된 region 데이타 list
			hashTagList: [], // 등록된 hashTag list
			searchAreaData: {},
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			),
		};
	},

	created() {
		//this.getHashTagListFromServer();
		this.procSelectedRegionYoutubeAreaDataList();
	},

	methods: {
		handleClick(event) {
			const href = event.currentTarget.getAttribute('href');
			window.parent.location.href = href;
			event.preventDefault();
			// console.log(href);
		},
		/* eslint-disable */
		async getHashTagListFromServer() {
			try {
				const response = await axios.get('/api/v1/getHashTagList');

				if (response.data) {
					this.hashTagList = response.data;
				}
			} catch (error) {
				alert('[getHashTagListFromServer][error][' + error.response.data + ']');
			}
		},
		async procSelectedRegionYoutubeAreaDataList() {
			try {
				const response = await axios.get('/api/v1/getYoutubeAreaInfoListByAreaName', {
					params: {
						areaName: '이순신광장',
					},
				});
				if (response.data) {
					this.youtubeAreaInfoList = response.data;
				}
			} catch (error) {
				alert('[selectGoogleSpotPlaceList][error]' + error.response.data);
			}
		},
	},
};
</script>

<style scoped>
/* 컴포넌트의 CSS 스타일은 여기에 작성합니다. */
.rounded-image {
	border-radius: 50%;
	width: 30px;
	height: 30px;
}

a .thum .moreVideoBTN {
	width: 180px;
	padding: 15px;
	text-align: Center;
	position: absolute;
	left: 50%;
	margin-left: -90px;
	top: 50%;
	transform: translateY(-50%);
	background: #323156;
	box-sizing: border-box;
	border-radius: 50px;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
	opacity: 0;
}
a .thum .moreVideoBTN .ico {
	width: 20px;
	display: inline-block;
	vertical-align: middle;
}
a .thum .moreVideoBTN .ico img {
	width: 100%;
}
a .thum .moreVideoBTN .text {
	font-size: 13px;
	color: #fff;
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
}
a .thum:hover .moreVideoBTN {
	opacity: 1;
	transition: 2s all;
}
</style>
