<template>
	<div class="videoDetail d-flex">
		<!-- YouTube 동영상 -->
		<div class="col-8">
			<div id="ytPlayer" class="video map"></div>
			<div id="textArea1" class="textArea">
				<div class="subject">
					{{ youtubeAreaInfo.title }}
				</div>
				<div class="iconArea d-flex">
					<div class="youtubeChannel">
						<p class="youtube_thum">
							<img
								:src="youtubeAreaInfo.profileImageUrl || '/images/youtubeIco.png'"
								class="rounded-image"
								alt="Profile Image"
							/>
						</p>
						<a
							:href="
								'https://www.youtube.com/channel/' + youtubeAreaInfo.channelId
							"
							target="_blank"
							><span>{{ youtubeAreaInfo.channelTitle }}</span></a
						>
					</div>
					<div class="date">
						{{ youtubeCreateTime }}
					</div>
				</div>
			</div>
		</div>

		<!-- Google Map -->
		<div class="col-4">
			<div id="googleMap" class="detail_map"></div>

			<!--랜드마크 표시 -->
			<div class="videoTag d-flex flex-wrap gap-1">
				<div v-for="(item, index) in landmarks" :key="index" class="p-2">
					<p>
						<span @click="moveToLandMark(item.lat, item.lng)">
							#{{ item.landmark }}
						</span>
					</p>
				</div>
			</div>

			<div class="store_list">
				<div
					v-for="spotPlace in googleSpotPlaceList"
					:key="spotPlace.spId"
					class="store_item d-flex align-items-center col-md-12 mb-3"
				>
					<p class="img">
						<img
							:src="
								spotPlace.googleSpotImage
									? 'https://storage.googleapis.com/allthestreet-images/spot_images/normal/' +
									  spotPlace.googleSpotImage.filePath
									: '/images/2.png'
							"
							class="card-img-top"
							alt="Spot Image"
							@mouseout="hiddenMarker"
							@mouseover="viewMarker(spotPlace)"
							@click="showSpotDetail(spotPlace)"
						/>
					</p>
					<div class="store_info">
						<p class="sotre_name">{{ spotPlace.spName }}</p>
						<div class="starList">
							<i
								v-for="n in Math.ceil(spotPlace.spRating)"
								:key="n"
								class="bi bi-star-fill text-warning"
							></i>
							<i
								v-for="n in 5 - Math.ceil(spotPlace.spRating)"
								:key="n"
								class="bi bi-star text-warning"
							></i>
							<span class="ml-1">{{ spotPlace.spRating }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="isSpotDetailVisible" class="modal-overlay" @click="closeSpotDetail"></div>

	<SpotDetailPc
		v-if="isSpotDetailVisible"
		:spot="spotDetailData"
		@close="closeSpotDetail"
	></SpotDetailPc>
</template>

<script>
import axios from 'axios';
import { toRaw } from 'vue';
import SpotDetailPc from '@/components/SpotDetailPc.vue';

export default {
	name: 'PalyYouTubePc',
	components: { SpotDetailPc },
	data() {
		return {
			isSpotDetailVisible: false, // 스팟 상세정보 창 띄우기 Flag
			spotDetailData: null, // 1개 스팟 상세 정보를 담은 object, SpotDetail component에서 사용
			showMap: true,
			youtubeCreateTime: '',
			spotMarker: null,

			mapData: {
				isExecuted: 'n',
				mapIdName: 'googleMap',
				notWalkedPathArray: [],
				walkedPathArray: [],
			},
			myMetaStreetInfo: {
				// metastreet 화면에서 marker 관련 정보
				metaId: '', // key
				chatId: '', // chat ID(loginId 또는 guest Id)
				regionId: 0, // areaId가 속한 region 의 Id
				areaId: 0, // areaId
				lat: '', // 위도
				lng: '', // 경도
				direction: 0, // marker 동선방향
				loginYn: 'n', // login 여부('y':login, 'n': not login)
				updateDate: 0,
				createDate: 0,
				isNewSession: false,
				loginUserInfo: {}, // login 사용자 정보
			},
			childWindow: null,
			YoutubeStatusEnum: {
				// Youtube Player 상태 관련 enum
				READY2READY: -1, // READY   => READY
				READY2RUNNING: 1, // READY   => RUNNING
				RUNNING2RUNNING: 2, // RUNNING => RUNNING
				RUNNING2STOP: 3, // RUNNING => STOP
				STOP2RUNNING: 4, // STOP    => RUNNING
				RUNNING2END: 5, // RUNNING => END
				WAIT2RUNNING: 6, // WAIT	   => RUNNING
				STOP2WAIT: 7, // STOP	   => WAIT
				WAIT2STOP: 8, // WAIT	   => STOP
				RUNNING2WAIT: 9, // RUNNING => WAIT
			},
			timeoutId: null, // youtube playing 관련 timeout 값 저장변수
			intervalId: null, // 뚜벅이 animation 관련 timer return 값 저장변수
			youtubeStatus: { before: '시작되지 않음', after: '시작되지 않음' }, // youtube play 시작 전
			poly: null,
			centerMarker: null,
			gStartLat: 0, // 지도 출발 위도 위치값(프로그램 시작 시 입력값으로 받음 )
			gStartLng: 0, // 지도 출발 경도 위치값(프로그램 시작 시 입력값으로 받음 )
			rateFactor: 1, // youtube 영상 재생속도 비율(1: 보통)
			savedMyWalkerMarker: null, // metastreet 화면상의 본인 WalkerMarker
			savedSpotPlaceMarker: null, // metastreet 화면상의 선택된 SpotPlaceMarker
			youtubeAreaInfoList: [], // 현재 리전의 유튜브들 리스트
			gcpSpotPlaceImgPath:
				'https://storage.googleapis.com/allthestreet-images/images/thumbnail/',
			gcpSpotPlaceNormalImgPath:
				'https://storage.googleapis.com/allthestreet-images/spot_images/normal/',
			gcpSpotPlaceSmallImgPath:
				'https://storage.googleapis.com/allthestreet-images/spot_images/small/',
			youtubeAreaInfo: {},
			googleSpotPlaceList: [],
			savedGoogleSpotPlace: {}, // 현재 click 한 spotPlace
			flightPlanCoordinates: [],
			flightPathArray: [], // 해당 region 내의 모든 경로 객체 Array
			spIdArr: [],
			isSkipRefreshSpotPlaceList: 0, // 주기적인 spotPlace 목록 갱신을 Skip
			streetVisitor: {},
			copyVisitor: {}, // streetVisitor 의 복사본
			changedStreetViewInfo: {}, // 교체된 지도상의 경로정보 정보체
			gVehicle: null, // 탈것 (1: 도보 , 2: 자동차)
			youtubeAndMapPara: {},

			apiKey: 'AIzaSyCJIeAFQw_vCXs66ASniuadE4F191BA1Ag',
			player: null,
			isFirstPlayerReady: false,
			playerState: null,
			isFirstPlayerChange: false,
			played: false,
			emailId: '',
			distanceLat: 0,
			myWalkerMarker: null,
			landmarks: [],
		};
	},

	mounted() {
		import('@/main').then((mainModule) => {
			mainModule.loadedGoogleMapsAPI.then(() => {
				this.initMap();
			});
		});
	},

	created() {
		// Vue Router를 사용하여 GET 매개변수 추출
		this.url = this.$route.query.url;
		this.type = Number(this.$route.query.type);
		this.areaId = Number(this.$route.query.areaId);
		this.regionId = Number(this.$route.query.regionId);
	},

	methods: {
		/* eslint-disable */
		initMap() {
			if (typeof google === 'object' && typeof google.maps === 'object') {
				switch (Number(this.type)) {
					case 1:
						// areaId,url 만 넘겨줌
						this.myMetaStreetInfo.areaId = this.areaId;
						this.youtubeAreaInfo.areaId = this.areaId;
						this.myMetaStreetInfo.regionId = this.regionId;
						this.youtubeAreaInfo.url = this.url;
						this.gStartLat = 0;
						this.gStartLng = 0;
						this.gVehicle = 1; // 도보
						this.spIdArr = [];
						break;
					default:
						break;
				}
				//this.selectGoogleSpotPlaceList(); // 초기 리전값에 따른 스팟 가져오기 (필요없음)
				this.manageYoutubeAreaInfoByRegionId(); // 리전에 따른 유튜브들 그리고 그 유튜브들의 분기점들 가져오기
				this.loadYoutubeScript(); //youtube 로드 후 play
			}
		},
		selectGoogleSpotPlaceList() {
			if (!this.isEmpty(this.myMetaStreetInfo.regionId)) {
				axios
					.get('/api/v1/selectGoogleSpotPlaceListAroundRegion', {
						params: {
							loginId: this.myMetaStreetInfo.chatId,
							regionId: this.myMetaStreetInfo.regionId,
						},
					})
					.then((response) => {
						if (response.data) {
							this.googleSpotPlaceList = response.data;
						}
					})
					.catch((error) => {
						// console.log(error);
						alert('[selectGoogleSpotPlaceList][error]' + error.response.data);
					});
			}
		},
		manageYoutubeAreaInfoByRegionId() {
			// 리전값에 따른 youtube 리스트 가져오기
			if (!this.isEmpty(this.myMetaStreetInfo.regionId)) {
				axios
					.get('/api/v1/getMaxYoutubeAreaInfoListPlusByRegionId', {
						params: {
							regionId: this.myMetaStreetInfo.regionId,
						},
					})
					.then((response) => {
						if (response.data) {
							this.youtubeAreaInfoList = response.data;
							this.makeRelatedYoutubeAreaRouteList();
							const foundItem = this.youtubeAreaInfoList.find(
								(item) =>
									Number(item.areaId) === Number(this.youtubeAreaInfo.areaId),
							);
							if (foundItem) {
								// 조건을 만족하는 항목을 찾았으면 this.youtubeAreaInfo를 업데이트합니다.
								this.youtubeAreaInfo = foundItem;
								this.youtubeCreateTime = foundItem.youtubeMakeDat.substring(
									0,
									10,
								);
								// console.log(this.youtubeAreaInfo);

								// 이미 추가한 랜드마크를 저장하기 위한 Set 객체 초기화
								let addedLandmarks = new Set();

								// tempYoutubeAreaRouteBranchList 배열 순회
								for (
									let i = 0;
									i < this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList.length;
									i++
								) {
									const item =
										this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList[i];

									// brLandMark 값이 있는 경우에만 landmarks 배열에 추가
									if (item.brLandMark && !addedLandmarks.has(item.brLandMark)) {
										this.landmarks.push({
											lat: item.lat,
											lng: item.lng,
											landmark: item.brLandMark,
										});

										// 이미 추가한 랜드마크를 Set에 추가
										addedLandmarks.add(item.brLandMark);
									}
								}
								// console.log(this.landmarks);
							}
							this.gatherYoutubeRouteCoordinate(); // youtube 분기점들을 매핑시킴
							this.drawMap();
							this.readyAnimation();
						}
					})
					.catch((error) => {
						// console.log(error);
						alert('[manageYoutubeAreaInfoByRegionId][error]' + error.response.data);
					});
			}
		},
		drawMap() {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			const lat = Number(tempYoutubeAreaRouteBranchList[0].lat);
			const lng = Number(tempYoutubeAreaRouteBranchList[0].lng);
			const mapOpt = {
				center: { lat: lat, lng: lng },
				zoom: 17,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				disableDefaultUI: true,
				zoomControl: true,
			};
			if (this.mapData.isExecuted === 'n') {
				//console.log('get google mapid isExecuted is n');
				this.mapData.mapId = new google.maps.Map(
					document.getElementById(this.mapData.mapIdName),
					mapOpt,
				);

				if (!this.isEmpty(this.googleSpotPlaceList)) {
					this.googleSpotPlaceList.forEach((googleSpotPlace) => {
						this.drawGoogleSpotPlaceAroundRegion(googleSpotPlace);
					});
				}
				this.drawNotWalkedRoutingLine();
				this.mapData.isExecuted = 'y';
			} else {
				// map이 새로 생성되므로 기존 것들을 지워주어야 함
				this.hideWalkedRoutingLine();
				this.hideNotWalkedRoutingLine();
				this.drawNotWalkedRoutingLine();
			}
		},
		drawGoogleSpotPlaceAroundRegion(googleSpotPlace) {
			const icon = {
				url: googleSpotPlace.spIconUrl,
				scaledSize: new google.maps.Size(10, 10),
			};
			const image = '/images/marker/sp_cover.png';
			const googleSpotMarker = new google.maps.Marker({
				position: {
					lat: Number(googleSpotPlace.lat),
					lng: Number(googleSpotPlace.lng),
				},
				map: this.mapData.mapId,
				icon: image,
			});

			googleSpotMarker.addListener('click', () => {
				this.manageGoogleSpotPlaceLayPopup(googleSpotPlace);
				this.savedGoogleSpotPlace = googleSpotPlace;
			});
		},
		gatherYoutubeRouteCoordinate() {
			// youtube의 분기점 값들을 매핑시킴
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			if (!this.isEmpty(tempYoutubeAreaRouteBranchList)) {
				tempYoutubeAreaRouteBranchList.forEach((branch) => {
					const posObj = {
						lat: Number(branch.lat),
						lng: Number(branch.lng),
						jumpYn: branch.jumpYn,
					};

					if (!this.isEmpty(posObj)) {
						this.flightPlanCoordinates.push(posObj);
					}
				});
			}
		},
		//drag event 시작
		sEvent() {
			this.sEventHandle();
		},
		sEventHandle() {
			this.pauseYoutube(); //youtube stop
			this.stopAnimation(); // lake add - 아바타 드래그시 youtube 강제실행 문제

			for (let idx = 0; idx < this.mapData.walkedPathArray.length; idx++) {
				toRaw(this.mapData.walkedPathArray[idx]).setMap(null);
			}
			for (let idx = 0; idx < this.mapData.notWalkedPathArray.length; idx++) {
				toRaw(this.mapData.notWalkedPathArray[idx]).setMap(null);
			}
			// 리전내 다른 유튜브 분기점 경로들 보이기
			for (let idx = 0; idx < this.flightPathArray.length; idx++) {
				this.flightPathArray[idx].setMap(this.mapData.mapId);
			}
		},
		// drag해서 움직이는 동안 계속 실행됨
		xEvent(event) {
			const markerLat = event.latLng.lat();
			const markerLng = event.latLng.lng();
			if (this.isEmpty(this.flightPathArray)) {
				return;
			}
			this.changedStreetViewInfo.isValidRoute = this.youtubeAreaInfoList.some(
				(areaInfo, x) => {
					if (!this.isEmpty(areaInfo)) {
						const isValid = this.xEventHandle(areaInfo, markerLat, markerLng);
						this.flightPathArray[x].setOptions({
							strokeColor: isValid ? '#FF0000' : '#f39e9e', // 레드선 또는 분홍색 설정
							strokeOpacity: 1,
							zIndex: isValid ? 20 : 0,
						});
						return isValid;
					}
					return false;
				},
			);
			// for (let x = 0; x < this.youtubeAreaInfoList.length; x++) {
			// 	if (!this.isEmpty(this.youtubeAreaInfoList[x])) {
			// 		const areaInfo = this.youtubeAreaInfoList[x];
			// 		if (this.xEventHandle(areaInfo, markerLat, markerLng)) {
			// 			this.changedStreetViewInfo.isValidRoute = true;
			// 			this.flightPathArray[x].setOptions({
			// 				strokeColor: '#FF0000',
			// 				strokeOpacity: 1,
			// 				zIndex: 20,
			// 			});
			// 			break;
			// 		} else {
			// 			this.changedStreetViewInfo.isValidRoute = false;
			// 			this.flightPathArray[x].setOptions({
			// 				strokeColor: '#f39e9e',
			// 				strokeOpacity: 1,
			// 				zIndex: 0,
			// 			});
			// 		}
			// 	}
			// }
		},
		xEventHandle(areaInfo, targetLat, targetLng) {
			const ACCEPT_DISTANCE = 0.01;
			let existInTheInterval = false;

			const tempYoutubeAreaRouteBranchList = areaInfo.tempYoutubeAreaRouteBranchList;
			if (this.isEmpty(tempYoutubeAreaRouteBranchList)) {
				return false;
			}

			const flightPlan2Coordinates = tempYoutubeAreaRouteBranchList
				.map((branch) => ({
					lat: Number(branch.lat),
					lng: Number(branch.lng),
				}))
				.filter((posObj) => !this.isEmpty(posObj));

			let copy2Visitor = {};

			this.setCopy2InitialValues(
				tempYoutubeAreaRouteBranchList,
				copy2Visitor,
				flightPlan2Coordinates,
			);

			for (let w = 0; w < tempYoutubeAreaRouteBranchList.length - 1; w++) {
				const betweenTime =
					tempYoutubeAreaRouteBranchList[w + 1].elapsedTime -
					tempYoutubeAreaRouteBranchList[w].elapsedTime;
				let elapsedTime = 0; // 구간 내에서의 누적시간
				let currLat = copy2Visitor.currentLat;
				let currLng = copy2Visitor.currentLng;

				while (elapsedTime <= betweenTime) {
					const distanceInKilometer = this.getDistanceFromLatLng(
						targetLat,
						targetLng,
						currLat,
						currLng,
					);

					if (distanceInKilometer >= 0 && distanceInKilometer <= ACCEPT_DISTANCE) {
						existInTheInterval = true;
						break;
					}

					elapsedTime++;
					currLat += copy2Visitor.deltaCalcLat;
					currLng += copy2Visitor.deltaCalcLng;
				}

				if (existInTheInterval) {
					this.changedStreetViewInfo.youtubeAreaInfo = areaInfo;
					this.changedStreetViewInfo.streetVisitor = copy2Visitor;
					this.changedStreetViewInfo.currLat = currLat;
					this.changedStreetViewInfo.currLng = currLng;
					break;
				} else {
					copy2Visitor.currRouteIdx++;
					if (copy2Visitor.currRouteIdx < copy2Visitor.maxRouteCnt) {
						this.setCopy2StreetVisitorValue(
							tempYoutubeAreaRouteBranchList,
							copy2Visitor,
							flightPlan2Coordinates,
						);
					}
				}
			}
			return existInTheInterval;
		},
		setCopy2InitialValues(
			tempYoutubeAreaRouteBranchList,
			copy2Visitor,
			flightPlan2Coordinates,
		) {
			copy2Visitor.currCalcElapsedTime = 0;
			copy2Visitor.currRealElapsedTime = 0;
			copy2Visitor.rateFactor = 0;
			copy2Visitor.currRouteIdx = 0;
			copy2Visitor.maxRouteCnt = tempYoutubeAreaRouteBranchList.length - 1;
			copy2Visitor.refreshCnt = 0;

			this.setCopy2StreetVisitorValue(
				tempYoutubeAreaRouteBranchList,
				copy2Visitor,
				flightPlan2Coordinates,
			);
		},
		setCopy2StreetVisitorValue(
			tempYoutubeAreaRouteBranchList,
			copy2Visitor,
			flightPlan2Coordinates,
		) {
			let idx = copy2Visitor.currRouteIdx;
			let betweenTime =
				tempYoutubeAreaRouteBranchList[idx + 1].elapsedTime -
				tempYoutubeAreaRouteBranchList[idx].elapsedTime;
			let distanceLat =
				flightPlan2Coordinates[idx + 1].lat - flightPlan2Coordinates[idx].lat;
			let distanceLng =
				flightPlan2Coordinates[idx + 1].lng - flightPlan2Coordinates[idx].lng;
			copy2Visitor.rateFactor = this.rateFactor;

			let deltaCalcLat, deltaCalcLng, deltaRealLat, deltaRealLng;

			if (betweenTime !== 0 && this.rateFactor !== 0) {
				deltaCalcLat = distanceLat / betweenTime;
				deltaCalcLng = distanceLng / betweenTime;
				deltaRealLat = distanceLat / (betweenTime / this.rateFactor);
				deltaRealLng = distanceLng / (betweenTime / this.rateFactor);
			} else {
				deltaCalcLat = 0;
				deltaCalcLng = 0;
				deltaRealLat = 0;
				deltaRealLng = 0;
			}
			copy2Visitor.betweenTime = betweenTime;
			copy2Visitor.deltaCalcLat = deltaCalcLat;
			copy2Visitor.deltaCalcLng = deltaCalcLng;
			copy2Visitor.deltaRealLat = deltaRealLat;
			copy2Visitor.deltaRealLng = deltaRealLng;
			copy2Visitor.currentLat = flightPlan2Coordinates[idx].lat;
			copy2Visitor.currentLng = flightPlan2Coordinates[idx].lng;
			copy2Visitor.currCalcElapsedTime = 0;
			copy2Visitor.currRealElapsedTime = 0;
		},

		eEvent(event) {
			this.gStartLat = event.latLng.lat(); // lake add
			this.gStartLng = event.latLng.lng(); // lake add
			this.eEventHandle(this.gStartLat, this.gStartLng);
			this.readyAnimation(); // lake add
		},
		eEventHandle(startLat, startLng) {
			// console.log('drag end');
			for (let idx = 0; idx < this.flightPathArray.length; idx++) {
				toRaw(this.flightPathArray[idx]).setMap(null);
			}

			for (let idx = 0; idx < this.mapData.notWalkedPathArray.length; idx++) {
				this.mapData.notWalkedPathArray[idx].setMap(this.mapData.mapId);
			}

			for (let idx = 0; idx < this.mapData.walkedPathArray.length; idx++) {
				this.mapData.walkedPathArray[idx].setMap(this.mapData.mapId);
			}
			this.procYoutubePosition(startLat, startLng);
		},
		procYoutubePosition(startLat, startLng) {
			switch (this.findYoutubePosition(startLat, startLng)) {
				case 0: // 현재 경로, 위치 유지
					//console.log('현재 경로, 위치 유지');
					this.playYoutube(0); // 0:정상 play 요청tag, 1: 강제 play 요청tag
					break;
				case 1: // 현 경로에서 위치 이동
					// console.log('현 경로에서 위치 이동');
					let direction = 0;
					if (this.streetVisitor.deltaRealLng > 0) {
						direction = 1; // 오른쪽(동쪽) 방향
					} else {
						direction = -1; // 왼쪽(서쪽) 방향
					}
					this.moveMapFromCenter(
						this.streetVisitor.currentLat,
						this.streetVisitor.currentLng,
						direction,
						1,
					);
					// intervalId = setInterval(controlProcess, 1000);
					this.playYoutube(0); // 0:정상 play 요청tag, 1: 강제 play 요청tag
					break;
				case 2: // 새로운 경로 선택
					// clearInterval(intervalId);
					//('새로운경로선택');
					// console.log('새로운경로 선택');
					this.changeToNewYoutubeAreaRoute(startLat, startLng);
					break;
				default:
					break;
			}
		},
		changeToNewYoutubeAreaRoute(startLat, startLng) {
			// 유효한 경로가 있는 경우
			if (this.changedStreetViewInfo.isValidRoute) {
				this.setupNewRoute(startLat, startLng);
			} else {
				this.playYoutube(0);
			}
		},

		async setupNewRoute(startLat, startLng) {
			this.youtubeAreaInfo = this.changedStreetViewInfo.youtubeAreaInfo;
			this.stopAnimation();
			this.flightPlanCoordinates = [];
			this.hideWalkedRoutingLine();
			this.hideNotWalkedRoutingLine();

			await this.gatherYoutubeRouteCoordinate();
			this.drawNotWalkedRoutingLine();

			// 비디오 로드와 설정
			this.player.loadVideoById(this.youtubeAreaInfo.url, 0, 'large');
			this.readyAnimation();
			this.playYoutube(0);

			// 비디오가 로드된 후에 작업 실행
			setTimeout(() => {
				if (this.findYoutubePosition(startLat, startLng) === 1) {
					const direction = this.streetVisitor.deltaRealLng > 0 ? 1 : -1;
					this.moveMapFromCenter(
						this.streetVisitor.currentLat,
						this.streetVisitor.currentLng,
						direction,
						1,
					);
				}
			}, 1000);
		},
		findYoutubePosition(fromLat, FromLng) {
			const ACCEPT_DISTANCE = 0.01;
			//console.log(this.youtubeAreaInfo);
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			let existInTheInterval = false;
			let findYoutubePositionTag = 0;
			this.setCopyInitialValues(1);
			for (let idx = 0; idx < this.flightPlanCoordinates.length - 1; idx++) {
				const betweenTime =
					tempYoutubeAreaRouteBranchList[idx + 1].elapsedTime -
					tempYoutubeAreaRouteBranchList[idx].elapsedTime;
				let elapsedTime = 0;
				let currLat = this.copyVisitor.currentLat;
				let currLng = this.copyVisitor.currentLng;
				while (elapsedTime <= betweenTime) {
					const distanceInKilometer = this.getDistanceFromLatLng(
						fromLat,
						FromLng,
						currLat,
						currLng,
					);
					if (distanceInKilometer >= 0 && distanceInKilometer <= ACCEPT_DISTANCE) {
						existInTheInterval = true;
						findYoutubePositionTag = 1;
						break;
					} else {
					}
					elapsedTime++;
					currLat = currLat + this.copyVisitor.deltaCalcLat;
					currLng = currLng + this.copyVisitor.deltaCalcLng;
				}
				if (existInTheInterval === true) {
					this.streetVisitor.betweenTime = betweenTime;
					this.streetVisitor.currRealElapsedTime =
						this.streetVisitor.currCalcElapsedTime = elapsedTime;
					this.streetVisitor.currRouteIdx = idx;
					this.streetVisitor.currentLat = currLat;
					this.streetVisitor.currentLng = currLng;
					this.streetVisitor.deltaRealLat = this.copyVisitor.deltaRealLat;
					this.streetVisitor.deltaRealLng = this.copyVisitor.deltaRealLng;
					this.playYoutubeAfterTime(
						tempYoutubeAreaRouteBranchList[idx].elapsedTime + elapsedTime,
					);
					break;
				} else {
					this.copyVisitor.currRouteIdx++;
					if (this.copyVisitor.currRouteIdx < this.copyVisitor.maxRouteCnt) {
						this.setCopyStreetVisitorValue();
					}
				}
			}
			if (existInTheInterval === false) {
				findYoutubePositionTag = 2;
			}
			return findYoutubePositionTag;
		},
		readyAnimation() {
			this.setInitialValues(0);
			this.intervalId = setInterval(this.controlProcess, 1000);
		},
		setInitialValues(procKindTag) {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			this.streetVisitor.currCalcElapsedTime = 0;
			this.streetVisitor.currRealElapsedTime = 0;
			this.streetVisitor.rateFactor = 0;
			this.streetVisitor.currRouteIdx = 0;
			this.streetVisitor.maxRouteCnt = tempYoutubeAreaRouteBranchList.length - 1;
			this.streetVisitor.refreshCnt = 0;

			if (procKindTag === 0) {
				this.drawMyWalkerMarker(
					this.flightPlanCoordinates[0].lat,
					this.flightPlanCoordinates[0].lng,
					0,
				);
			}
			this.setStreetVisitorValue();
		},
		setCopyInitialValues(procKindTag) {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			this.copyVisitor.currCalcElapsedTime = 0;
			this.copyVisitor.currRealElapsedTime = 0;
			this.copyVisitor.rateFactor = 0;
			this.copyVisitor.currRouteIdx = 0;
			this.copyVisitor.maxRouteCnt = tempYoutubeAreaRouteBranchList.length - 1;
			this.copyVisitor.refreshCnt = 0;

			if (procKindTag === 0) {
				this.drawMyWalkerMarker(
					this.flightPlanCoordinates[0].lat,
					this.flightPlanCoordinates[0].lng,
					0,
				);
			}
			this.setCopyStreetVisitorValue();
		},
		setStreetVisitorValue() {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			const idx = this.streetVisitor.currRouteIdx;
			const betweenTime =
				tempYoutubeAreaRouteBranchList[idx + 1].elapsedTime -
				tempYoutubeAreaRouteBranchList[idx].elapsedTime;
			const distanceLat =
				this.flightPlanCoordinates[idx + 1].lat - this.flightPlanCoordinates[idx].lat;
			const distanceLng =
				this.flightPlanCoordinates[idx + 1].lng - this.flightPlanCoordinates[idx].lng;
			this.streetVisitor.rateFactor = this.rateFactor;

			if (betweenTime !== 0 && this.rateFactor !== 0) {
				const deltaCalcLat = distanceLat / betweenTime;
				const deltaCalcLng = distanceLng / betweenTime;

				const deltaRealLat = distanceLat / (betweenTime / this.rateFactor);
				const deltaRealLng = distanceLng / (betweenTime / this.rateFactor);

				this.streetVisitor.deltaCalcLat = deltaCalcLat;
				this.streetVisitor.deltaCalcLng = deltaCalcLng;
				this.streetVisitor.deltaRealLat = deltaRealLat;
				this.streetVisitor.deltaRealLng = deltaRealLng;
			} else {
				this.streetVisitor.deltaCalcLat = 0;
				this.streetVisitor.deltaCalcLng = 0;
				this.streetVisitor.deltaRealLat = 0;
				this.streetVisitor.deltaRealLng = 0;
			}

			this.streetVisitor.betweenTime = betweenTime;
			this.streetVisitor.currentLat = this.flightPlanCoordinates[idx].lat;
			this.streetVisitor.currentLng = this.flightPlanCoordinates[idx].lng;
			this.streetVisitor.currCalcElapsedTime = 0;
			this.streetVisitor.currRealElapsedTime = 0;
		},
		setCopyStreetVisitorValue() {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			const idx = this.copyVisitor.currRouteIdx;
			const betweenTime =
				tempYoutubeAreaRouteBranchList[idx + 1].elapsedTime -
				tempYoutubeAreaRouteBranchList[idx].elapsedTime;
			const distanceLat =
				this.flightPlanCoordinates[idx + 1].lat - this.flightPlanCoordinates[idx].lat;
			const distanceLng =
				this.flightPlanCoordinates[idx + 1].lng - this.flightPlanCoordinates[idx].lng;
			this.copyVisitor.rateFactor = this.rateFactor;

			let deltaCalcLat = 0;
			let deltaCalcLng = 0;
			let deltaRealLat = 0;
			let deltaRealLng = 0;

			if (betweenTime !== 0 && this.rateFactor !== 0) {
				deltaCalcLat = distanceLat / betweenTime;
				deltaCalcLng = distanceLng / betweenTime;
				deltaRealLat = distanceLat / (betweenTime / this.rateFactor);
				deltaRealLng = distanceLng / (betweenTime / this.rateFactor);
			}

			this.copyVisitor.betweenTime = betweenTime;
			this.copyVisitor.deltaCalcLat = deltaCalcLat;
			this.copyVisitor.deltaCalcLng = deltaCalcLng;
			this.copyVisitor.deltaRealLat = deltaRealLat;
			this.copyVisitor.deltaRealLng = deltaRealLng;
			this.copyVisitor.currentLat = this.flightPlanCoordinates[idx].lat;
			this.copyVisitor.currentLng = this.flightPlanCoordinates[idx].lng;

			this.copyVisitor.currCalcElapsedTime = 0;
			this.copyVisitor.currRealElapsedTime = 0;
		},
		resetStreetVisitorValue() {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			const idx = this.streetVisitor.currRouteIdx;
			const betweenTime =
				tempYoutubeAreaRouteBranchList[idx + 1].elapsedTime -
				tempYoutubeAreaRouteBranchList[idx].elapsedTime;
			this.streetVisitor.rateFactor = this.rateFactor;
			this.streetVisitor.betweenTime = betweenTime;

			let deltaRealLat = 0;
			let deltaRealLng = 0;

			if (betweenTime !== 0 && this.rateFactor !== 0) {
				deltaRealLat = this.distanceLat / (betweenTime / this.rateFactor);
				deltaRealLng = this.distanceLng / (betweenTime / this.rateFactor);
			}

			this.streetVisitor.deltaRealLat = deltaRealLat;
			this.streetVisitor.deltaRealLng = deltaRealLng;
		},
		controlProcess() {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			const before = this.youtubeStatus.before;
			const after = this.youtubeStatus.after;
			if (before === '재생 중' && after === '재생 중') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.RUNNING2RUNNING;
				this.procAnimation();
			} else if (before === '시작되지 않음' && after === '시작되지 않음') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.READY2READY;
			} else if (before === '버퍼링 중' && after === '재생 중') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.WAIT2RUNNING;
				if (
					this.streetVisitor.currRouteIdx === 0 &&
					this.streetVisitor.currElapsedTime === 0 &&
					this.getCurrentTime() <= tempYoutubeAreaRouteBranchList[0].elapsedTime + 5
				) {
					this.streetVisitor.statusTrans = this.YoutubeStatusEnum.READY2RUNNING;
				}
				this.procAnimation();
			} else if (before === '재생 중' && after === '일시중지 됨') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.RUNNING2STOP;
			} else if (before === '일시중지 됨' && after === '재생 중') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.STOP2RUNNING;
				this.procAnimation();
			} else if (before === '재생 중' && after === '종료됨') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.RUNNING2END;
				if (this.otherWalkerCnt > 0) {
					this.afterStopAnimation();
				} else {
					this.stopAnimation();
				}
			} else if (before === '일시중지 됨' && after === '일시중지 됨') {
				this.playYoutube(1); // 0: 정상 play 요청 tag, 1: 강제 play 요청 tag
			} else if (before === '종료됨' && after === '종료됨') {
				if (this.otherWalkerCnt > 0) {
					this.afterStopAnimation();
				}
			} else if (before === '일시중지 됨' && after === '버퍼링 중') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.STOP2WAIT;
				this.playYoutube(1);
			} else if (before === '버퍼링 중' && after === '시작되지 않음') {
				this.streetVisitor.statusTrans = this.YoutubeStatusEnum.WAIT2STOP;
			} else {
				// 정의되지 않은 상태 처리
				// alert('[정의되 않은 상태 입니다.][before:' + before + '][after:' + after + ']');
			}

			this.youtubeStatus.before = after;
		},
		stopAnimation() {
			if (this.intervalId !== null) {
				clearInterval(this.intervalId);
			}
		},
		procAnimation() {
			if (
				this.streetVisitor.statusTrans === this.YoutubeStatusEnum.RUNNING2RUNNING ||
				this.streetVisitor.statusTrans === this.YoutubeStatusEnum.READY2RUNNING ||
				this.streetVisitor.statusTrans === this.YoutubeStatusEnum.STOP2RUNNING
			) {
				this.regularAnimation();
			} else {
				this.irregularAnimation();
			}
		},
		regularAnimation() {
			const tempYoutubeAreaRouteBranchList =
				this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
			const nextLat = this.streetVisitor.currentLat + this.streetVisitor.deltaRealLat;
			const nextLng = this.streetVisitor.currentLng + this.streetVisitor.deltaRealLng;
			const direction = this.streetVisitor.deltaRealLng > 0 ? 1 : -1;

			this.moveMapFromCenter(nextLat, nextLng, direction, 0);

			this.streetVisitor.currentLat = nextLat;
			this.streetVisitor.currentLng = nextLng;
			this.streetVisitor.currRealElapsedTime += this.streetVisitor.rateFactor;

			if (this.streetVisitor.currRealElapsedTime >= this.streetVisitor.betweenTime) {
				this.streetVisitor.currRouteIdx++;
				if (this.streetVisitor.currRouteIdx < this.streetVisitor.maxRouteCnt) {
					this.setStreetVisitorValue();
				} else {
					clearInterval(this.intervalId);
				}
			}
			this.streetVisitor.refreshCnt++;
		},
		irregularAnimation() {
			const tempYoutubeAreaRouteBranchList = [
				...this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList,
			];
			let elapsedTime = this.getCurrentTime();
			if (elapsedTime > 0) {
				this.setInitialValues(1);
				for (let idx = 0; idx < tempYoutubeAreaRouteBranchList.length; idx++) {
					const betweenTime =
						tempYoutubeAreaRouteBranchList[idx + 1].elapsedTime -
						tempYoutubeAreaRouteBranchList[idx].elapsedTime;

					if (betweenTime <= elapsedTime) {
						elapsedTime -= betweenTime;
						this.streetVisitor.currRouteIdx++;

						if (this.streetVisitor.currRouteIdx < this.streetVisitor.maxRouteCnt) {
							this.setStreetVisitorValue();
						} else {
							clearInterval(this.intervalId);
							break;
						}
					} else {
						while (this.streetVisitor.currCalcElapsedTime < elapsedTime) {
							const nextLat =
								this.streetVisitor.currentLat + this.streetVisitor.deltaCalcLat;
							const nextLng =
								this.streetVisitor.currentLng + this.streetVisitor.deltaCalcLng;

							this.streetVisitor.currentLat = nextLat;
							this.streetVisitor.currentLng = nextLng;
							this.streetVisitor.currCalcElapsedTime++;
						}
						this.streetVisitor.currRealElapsedTime =
							this.streetVisitor.currCalcElapsedTime;
						const direction = this.streetVisitor.deltaRealLng > 0 ? 1 : -1;

						this.moveMapFromCenter(
							this.streetVisitor.currentLat,
							this.streetVisitor.currentLng,
							direction,
							1,
						);
						break;
					}
				}
			} else {
				this.stopAnimation();
				this.readyAnimation();
			}
		},
		afterStopAnimation() {
			const nextLat = this.streetVisitor.currentLat;
			const nextLng = this.streetVisitor.currentLng;
			let direction = 0;

			if (this.streetVisitor.deltaRealLng > 0) {
				direction = 1; // 오른쪽(동쪽) 방향
			} else {
				direction = -1; // 왼쪽(서쪽) 방향
			}

			this.moveMapFromCenter(nextLat, nextLng, direction, 0);
		},
		//drawRouteKindTag: route line을 작성하는 방법
		// 0: drawing from currentPosition, 1: drawing from start position
		moveMapFromCenter(lat, lng, direction, drawRouteKindTag) {
			const mapCenter = { lat: lat, lng: lng };
			this.mapData.mapId.setCenter(mapCenter);
			this.manageDrawMyWalkers(lat, lng, direction, drawRouteKindTag);
			if (this.streetVisitor.refreshCnt % 5 === 0) {
				if (this.isSkipRefreshSpotPlaceList === 0) {
					this.updateSpotPlaceList(lat, lng);
				}
			}
		},
		manageDrawMyWalkers(lat, lng, direction, drawRouteKindTag) {
			this.drawMyWalkerMarker(lat, lng, direction);
			if (drawRouteKindTag !== 0) {
				// 0인 경우 기존 walker 경로 지우기
				this.hideWalkedRoutingLine();
			}
			this.drawWalkerRoutingLine(lat, lng, drawRouteKindTag);
		},
		drawMyWalkerMarker(lat, lng, direction) {
			// 여수 아이콘 static 코드 //
			// const image = {
			// 	url: 'images/yeosu_logo.png', // 이미지 URL
			// 	scaledSize: new google.maps.Size(50, 50), // 크기 지정 (픽셀 단위)
			// };
			// if (!this.myWalkerMarker) {
			// 	this.myWalkerMarker = new google.maps.Marker({
			// 		draggable: true,
			// 		icon: image,
			// 	});
			// }
			// this.myWalkerMarker.addListener('dragstart', this.sEvent);
			// this.myWalkerMarker.addListener('drag', this.xEvent);
			// this.myWalkerMarker.addListener('dragend', this.eEvent);
			// this.myWalkerMarker.setPosition({ lat, lng });
			// if (this.mapData.mapId) {
			// 	this.myWalkerMarker.setMap(this.mapData.mapId);
			// }

			// 아바타 방향전환 코드 //
			let markerUrl = '';
			switch (direction) {
				case 0:
					markerUrl = '/images/marker/right_walking_icon.gif';
					break;
				case 1:
					markerUrl = '/images/marker/right_walking_icon.gif';
					break;
				case -1:
					markerUrl = '/images/marker/left_walking_icon.gif';
					break;
				default:
					markerUrl = '/images/marker/right_walking_icon.gif';
					break;
			}
			let image = {
				url: markerUrl, // 이미지 URL
				scaledSize: new google.maps.Size(50, 50), // 크기 지정 (픽셀 단위)
			};
			if (!this.myWalkerMarker) {
				this.myWalkerMarker = new google.maps.Marker({
					draggable: true,
					icon: image,
				});
				this.myWalkerMarker.addListener('dragstart', this.sEvent);
				this.myWalkerMarker.addListener('drag', this.xEvent);
				this.myWalkerMarker.addListener('dragend', this.eEvent);
			}

			// 마커의 위치와 아이콘 설정
			this.myWalkerMarker.setPosition({ lat, lng });
			this.myWalkerMarker.setIcon(markerUrl);

			// 지도에 마커 추가
			if (this.mapData.mapId) {
				this.myWalkerMarker.setMap(this.mapData.mapId);
			}
		},

		updateSpotPlaceList(lat, lng) {
			const youtubeAreaRoute = {
				lat: lat.toString(),
				lng: lng.toString(),
				regionId: Number(this.regionId),
				loginId: this.emailId,
				radius: 0.08,
			};
			axios
				.post(
					'/api/v1/getGoogleSpotPlaceListByRegionPositionWithRadius',
					youtubeAreaRoute,
				)
				.then((response) => {
					if (response.data) {
						const nearSpotPlaceList = response.data;
						//this.addSpotPlaceRows(nearSpotPlaceList);
						this.googleSpotPlaceList = nearSpotPlaceList;
						// console.log(nearSpotPlaceList);
					}
				})
				.catch((error) => {
					alert(`[updateSpotPlaceList][error][${error}]`);
				});
		},
		drawNearSpotPlaceMarkers(nearSpotPlace) {
			const lat = Number(nearSpotPlace.lat);
			const lng = Number(nearSpotPlace.lng);

			// Marker 데이터를 Vue 데이터에 추가
			const spotPlaceMarker = new google.maps.Marker({
				position: { lat, lng },
				map: this.mapData.mapId,
				// label: nearSpotPlace.spName,
				icon: '/images/marker/recom_spot_marker.png', // 기본 아이콘
			});

			// InfoWindow 데이터를 Vue 데이터에 추가
			const infowindow = new google.maps.InfoWindow({
				content: nearSpotPlace.spName,
				maxWidth: 100,
			});

			spotPlaceMarker.addListener('mouseover', () => {
				infowindow.open(this.mapData.mapId, spotPlaceMarker);
			});

			spotPlaceMarker.addListener('mouseout', () => {
				infowindow.close();
			});

			let findYn = false;
			if (!this.isEmpty(this.spIdArr)) {
				for (const spId of this.spIdArr) {
					if (spId === nearSpotPlace.spId) {
						spotPlaceMarker.setIcon('/images/marker/select_spot_marker.png');
						findYn = true;
						break;
					}
				}
			}

			if (findYn === false) {
				spotPlaceMarker.setIcon('/images/marker/recom_spot_marker.png');
			}

			spotPlaceMarker.addListener('click', () => {
				this.manageGoogleSpotPlaceLayPopup(nearSpotPlace);
				this.savedGoogleSpotPlace = nearSpotPlace;
			});

			this.savedSpotPlaceMarker = spotPlaceMarker;
		},
		drawNotWalkedRoutingLine() {
			//const notWalkedPath = [];
			this.flightPlanCoordinates.forEach((coordinate, i, coordinates) => {
				if (i < coordinates.length - 1 && coordinates[i + 1].jumpYn === 'n') {
					const pathLine = new google.maps.Polyline({
						path: [coordinate, coordinates[i + 1]],
						geodesic: true,
						strokeColor: '#f39e9e', // 분홍
						strokeOpacity: 1.0,
						strokeWeight: 5,
						zIndex: 1,
					});

					pathLine.setMap(this.mapData.mapId);
					//notWalkedPath.push(pathLine);
					this.mapData.notWalkedPathArray.push(pathLine);

					pathLine.addListener('click', (event) => {
						const startLat = event.latLng.lat();
						const startLng = event.latLng.lng();
						//('clicked path line');
						this.procYoutubePosition(startLat, startLng);
					});
				}
			});

			//this.mapData.notWalkedPathArray = notWalkedPath;
		},
		drawWalkerRoutingLine(toLat, toLng, drawRouteKindTag) {
			const lastFromObj = {};
			const lastToObj = {};
			//const walkedPathArray = this.mapData.walkedPathArray;
			const idx = this.streetVisitor.currRouteIdx;

			if (idx < this.flightPlanCoordinates.length) {
				// drawRouteKindTag: route line을 작성하는 방법
				// 0: drawing from currentPosition, 1: drawing from start position
				if (drawRouteKindTag !== 0) {
					//이미 지나온 경로를 찍었을때
					for (let i = 0; i < idx; i++) {
						if (this.flightPlanCoordinates[i + 1].jumpYn === 'n') {
							const pathLine = new google.maps.Polyline({
								path: [
									this.flightPlanCoordinates[i],
									this.flightPlanCoordinates[i + 1],
								],
								geodesic: true,
								strokeColor: '#FF0000',
								strokeOpacity: 1.0,
								strokeWeight: 5,
								zIndex: 2,
							});
							pathLine.setMap(this.mapData.mapId);
							//walkedPathArray.push(pathLine);
							this.mapData.walkedPathArray.push(pathLine);

							pathLine.addListener('click', (event) => {
								const startLat = event.latLng.lat();
								const startLng = event.latLng.lng();
								//('click route point:' + startLng);
								this.procYoutubePosition(startLat, startLng);
							});
						}
					}
					lastFromObj.lat = this.flightPlanCoordinates[idx].lat;
					lastFromObj.lng = this.flightPlanCoordinates[idx].lng;
				} else {
					lastFromObj.lat = this.streetVisitor.currentLat;
					lastFromObj.lng = this.streetVisitor.currentLng;
				}

				lastToObj.lat = toLat;
				lastToObj.lng = toLng;

				// 현재 위치의 직전 분기점에서의 drawing 작업
				const pathLine = new google.maps.Polyline({
					path: [lastFromObj, lastToObj],
					geodesic: true,
					strokeColor: '#FF0000',
					strokeOpacity: 1.0,
					strokeWeight: 5,
					zIndex: 2,
				});
				pathLine.setMap(this.mapData.mapId);
				//walkedPathArray.push(pathLine);
				this.mapData.walkedPathArray.push(pathLine);

				pathLine.addListener('click', (event) => {
					const startLat = event.latLng.lat();
					const startLng = event.latLng.lng();
					this.procYoutubePosition(startLat, startLng);
				});
			}
		},
		// addSpotPlaceRows(nearSpotPlaceList) {
		// 	this.googleSpotPlaceList = nearSpotPlaceList;
		// 	// for (let x in nearSpotPlaceList) {
		// 	// 	let data = nearSpotPlaceList[x];
		// 	// 	this.fnSpotPlace(data);
		// 	// }
		// },
		fnSpotPlace(data) {
			// spId를 사용하여 요소를 선택할 때 jQuery 대신 Vue.js 이벤트 처리 방식을 사용합니다.
			// 클릭 이벤트 처리
			const spotElement = document.getElementById(data.spId);
			spotElement.addEventListener('click', () => {
				this.manageGoogleSpotPlaceLayPopup(data);
			});

			// 마우스 오버 이벤트 처리
			spotElement.addEventListener('mouseover', () => {
				this.isSkipRefreshSpotPlaceList = 1;
				this.drawNearSpotPlaceMarkers(data);
			});

			// 마우스 아웃 이벤트 처리
			spotElement.addEventListener('mouseout', () => {
				this.deleteSavedSpotPlaceMarker();
				this.isSkipRefreshSpotPlaceList = 0;
			});

			// tab2_view 클래스가 있는 요소를 클릭하는 이벤트 처리
			const tab2ViewElements = document.querySelectorAll('.tab2_view');
			tab2ViewElements.forEach((element) => {
				element.addEventListener('click', () => {
					const tabs = document.querySelectorAll('ul.tabs li');
					tabs.forEach((tab) => {
						tab.classList.remove('active');
					});
					const tab2 = document.querySelector('ul.tabs li:nth-child(2)');
					tab2.classList.add('active');
					const tabContents = document.querySelectorAll('.tab_content');
					tabContents.forEach((content) => {
						content.style.display = 'none';
					});
					const tabContent2 = document.querySelector('.tab_content:nth-child(2)');
					tabContent2.style.display = 'block';
				});
			});
		},
		onYoutubeCilck(spId) {
			if (!this.isEmpty(spId)) {
				let isFind = false;
				let findSpotPlace = {};
				for (let x in this.googleSpotPlaceList) {
					let googleSpotPlace = this.googleSpotPlaceList[x];
					if (spId == googleSpotPlace.spId) {
						findSpotPlace = googleSpotPlace;
						isFind = true;
						break;
					}
				}
			}
			if (isFind == true) {
				this.manageGoogleSpotPlaceLayPopup(findSpotPlace);
				$('ul.tabs li').removeClass('active');
				$('ul.tabs li:nth-child(2)').removeClass('active');
				$('ul.tabs li:first').addClass('active').show();
				$('.tab_content').hide();
				$('.tab_content:first').show();
			}
		},
		hideNotWalkedRoutingLine() {
			if (!this.isEmpty(this.mapData.notWalkedPathArray)) {
				// 모든 마커를 한 번에 지우기
				// this.mapData.notWalkedPathArray.forEach((marker) => {
				// 	marker.setMap(null);
				// });
				// // 배열 비우기
				// this.mapData.notWalkedPathArray.length = 0;
				for (let idx = 0; idx < this.mapData.notWalkedPathArray.length; idx++) {
					toRaw(this.mapData.notWalkedPathArray[idx]).setMap(null);
				}
			}
		},
		hideWalkedRoutingLine() {
			if (!this.isEmpty(this.mapData.walkedPathArray)) {
				// // 모든 마커를 한 번에 지우기
				// this.mapData.walkedPathArray.forEach((marker) => {
				// 	marker.setMap(null);
				// });
				// // 배열 비우기
				// this.mapData.walkedPathArray.length = 0;
				for (let idx = 0; idx < this.mapData.walkedPathArray.length; idx++) {
					toRaw(this.mapData.walkedPathArray[idx]).setMap(null);
				}
			}
		},
		deleteSavedSpotPlaceMarker() {
			if (!this.isEmpty(this.savedSpotPlaceMarker)) {
				toRaw(this.savedSpotPlaceMarker).setMap(null);
				//this.savedSpotPlaceMarker = null;
			}
		},
		deleteGoogleSpotPlaceYoutubeList() {
			const spotPlaceYoutubeTabDiv = document.getElementById('tab1');
			while (spotPlaceYoutubeTabDiv.hasChildNodes()) {
				spotPlaceYoutubeTabDiv.removeChild(spotPlaceYoutubeTabDiv.firstChild);
			}
		},
		makeRelatedYoutubeAreaRouteList() {
			// 해당 유튜브의 분기점들 모으기
			for (const x in this.youtubeAreaInfoList) {
				if (!this.isEmpty(this.youtubeAreaInfoList[x])) {
					const tempYoutubeAreaRouteBranchList =
						this.youtubeAreaInfoList[x].tempYoutubeAreaRouteBranchList;
					const posObjs = [];
					// console.log(tempYoutubeAreaRouteBranchList);
					for (const y in tempYoutubeAreaRouteBranchList) {
						const posObj = {
							lat: Number(tempYoutubeAreaRouteBranchList[y].lat),
							lng: Number(tempYoutubeAreaRouteBranchList[y].lng),
							jumpYn: tempYoutubeAreaRouteBranchList[y].jumpYn,
						};
						posObjs.push(posObj);
					}

					// 여기서 makeRelatedYoutubeAreaRoute 메서드를 호출하고 posObjs를 전달합니다.
					this.makeRelatedYoutubeAreaRoute(posObjs); //분기점들을 이용한 유튜브 경로 그리기
				}
			}
		},
		makeRelatedYoutubeAreaRoute(posObjs) {
			let lineColor = '#FF0000'; // 레드라인
			const lineSymbol = {
				path: 'M 0,-1 0,1',
				strokeOpacity: 1,
				scale: 3,
			};

			const poly = [];
			const bounds = new google.maps.LatLngBounds();

			for (let i = 0; i < posObjs.length; i++) {
				const lat = posObjs[i]; // lat이 객체로 이미 lat, lng, jumpYn값을 가지고 있어서  google.maps.LatLng(lat, lng);에서 에러 발생 그래서 lng를 null로 잡아줌 lake2042-0923
				const lng = null;
				const coords = new google.maps.LatLng(lat, lng);
				poly.push(coords);
				bounds.extend(coords);
			}

			const pathLine = new google.maps.Polyline({
				path: poly,
				geodesic: true,
				strokeColor: '#f39e9e', //분홍
				strokeOpacity: 1.0,
				strokeWeight: 5,
			});
			// flightPathArray 배열에 pathLine 추가
			this.flightPathArray.push(pathLine);
		},
		manageGoogleSpotPlaceLayPopup(googleSpotPlace) {
			// Vue.js를 사용하여 DOM 요소를 선택하고 스타일 및 내용을 변경합니다.
			this.$refs.googlePlace.style.display = 'flex';
			this.$refs.spotPlaceLayupTitle.innerHTML = googleSpotPlace.spName;
		},
		makeSpotPlaceYoutubeTab(googleSpotPlace, addedSpotYoutubeInfo) {
			const spotPlaceyoutubeList = googleSpotPlace.googleSpotYoutubeList;
			this.deleteGoogleSpotPlaceYoutubeList();
			if (!this.isEmpty(spotPlaceyoutubeList)) {
				const spotPlaceYoutubeTab2Div = document.getElementById('tab1');
				let htmlString = '';
				htmlString += '<div class="popup-scroll">';

				// tab 내용
				htmlString += '<div class="popup_title_area">';
				htmlString +=
					'<p class="store_pop_tit"><strong>이 장소의 다른 관련 동영상이 있나요?</strong><br/>올더스트릿에 공유해주세요. 뱃지를 드립니다.</p>';
				htmlString +=
					'<p class="mypageYoutubeItem"><a href="#popup_reg" class="btn">+ 동영상 등록</a></p>';
				htmlString += '</div>';
				htmlString += '<div class="tabyoutube_flex">';

				for (let idx in spotPlaceyoutubeList) {
					const spotPlaceYoutube = spotPlaceyoutubeList[idx];
					const spId = spotPlaceYoutube.spId;
					const channelId = spotPlaceYoutube.channelId;
					const channelTitle = spotPlaceYoutube.channelTitle;
					const profileImageUrl = spotPlaceYoutube.profileImageUrl;
					const url = spotPlaceYoutube.url;
					const stTime = spotPlaceYoutube.stTime;
					const playUrl = url + '?start=' + stTime.toString();
					const title = spotPlaceYoutube.title;
					const youtubeMakeDat = !this.isEmpty(spotPlaceYoutube.youtubeMakeDat)
						? spotPlaceYoutube.youtubeMakeDat.split(' ')
						: null;

					htmlString += '<div class="tabyoutube_item">';
					htmlString += '<div class="video_container">';
					htmlString += `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${playUrl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
					htmlString += '</div>';
					htmlString += '<div class="textArea">';
					if (!this.isEmpty(title))
						htmlString += '<div class="subject">' + title + '</div>';
					else htmlString += '<div class="subject"></div>';
					htmlString += '<div class="iconArea">';
					htmlString += '<div class="youtubeChannel">';
					htmlString += '<p class="youtube_thum">';
					if (!this.isEmpty(profileImageUrl)) {
						htmlString += `<img src="${profileImageUrl}">`;
					} else {
						htmlString += '<img src="/images/youtubeIco.png" >';
					}
					htmlString += '</p>';
					if (!this.isEmpty(channelTitle)) {
						htmlString += `<a href="https://www.youtube.com/channel/${channelId}" target="_blank">`;
						htmlString += `<span>${channelTitle}</span>`;
						htmlString += '</a>';
					} else htmlString += '<span>유튜브 채널이름</span>';
					htmlString += '</div>';
					if (!this.isEmpty(youtubeMakeDat) && !this.isEmpty(youtubeMakeDat[0]))
						htmlString += '<div class="date">' + youtubeMakeDat[0] + '</div>';
					else htmlString += '<div class="date"></div>';
					htmlString += '<div class="functionIco1">';
					htmlString += '<a href="javascript:onclick(\'' + url + '\')">';
					htmlString += '<i class="xi-share-alt-o xi-x"></i>';
					htmlString += '</a>';
					htmlString += '<i class="xi-bookmark-o xi-x"></i>';
					htmlString += '</div>';
					htmlString += '</div>';
					htmlString += '</div>';
				}

				htmlString += '</div>';

				// 동영상 등록 영역
				htmlString += '<div class="popup_youtube_add">';
				htmlString += '<div class="popup_title_area">';
				htmlString +=
					'<p class="store_pop_tit"><strong>이 장소의 다른 관련 동영상이 있나요?</strong><br/>올더스트릿에 공유해주세요. 뱃지를 드립니다.</p>';
				htmlString += '<div class="searchBox">';
				htmlString +=
					'<input type="text" id="youtubeUrl" placeholder="해당 유튜브 ID 입력 (...v=' +
					"'유튜브 ID'" +
					')" class="inputBox">';
				htmlString +=
					'<input type="image" src="/images/searchIco.png" class="inputBTN" @click="procYoutubeUrl()">';
				htmlString += '</div>';
				htmlString += '</div>';

				htmlString += '<div class="popup_registration_area" id="popup_reg">';
				htmlString += '<div class="popup_registration_txt">';
				htmlString += '<p class="tit">동영상 등록방법</p>';
				htmlString += '<ol>';
				htmlString +=
					'<li>이 장소와 관련된 동영상의 유튜브 ID 입력 후 <i class="xi-search xi-x"></i> 누르면 해당 동영상이 플레이됩니다.</li>';
				htmlString +=
					'<li>동영상 확인 후 ' +
					"'동영상 등록'" +
					'버튼을 누르면 등록이 됩니다.</li>';
				htmlString += '<li>관리자가 확인 후 최종 등록 및 뱃지를 지급합니다.</li>';
				htmlString += '</ol>';
				htmlString += '</div>';
				htmlString += '<div class="pop_registration_youtube">';
				htmlString += '<div class="video_container">';
				if (
					!this.isEmpty(addedSpotYoutubeInfo) &&
					!this.isEmpty(addedSpotYoutubeInfo.url)
				)
					htmlString += `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${addedSpotYoutubeInfo.url}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
				else
					htmlString +=
						'<iframe width="100%" height="100%" src="https://www.youtube.com/embed/XNwO78G41Tc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

				htmlString += '</div>';
				htmlString +=
					'<p class="mypageYoutubeItem"><a href="javascript:registerGoogleSpotPlaceYoutubeInfo()" class="btn">+ 동영상 등록 완료</a></p>';
				htmlString += '</div>';
				htmlString += '</div>';
				htmlString += '</div>';
				// 동영상 등록 영역

				htmlString += '</div>';
				spotPlaceYoutubeTab2Div.innerHTML = htmlString;
			}
		},
		makeSpotPlaceInfoTab(data, tag) {
			//재작성 필요
			var openTimeObj = getTodayOpenTime(data);
			var spotPlaceTab2Div = document.getElementById('tab2');
			var htmlString = '';
			htmlString += '<div class="popup-scroll">';
			htmlString += '<div class="tabyoutube_flex">';
			htmlString += '<div class="tabyoutube_item">';
			htmlString += '<p class="google_photo">';
			if (!isEmpty(data.googleSpotImage) && !isEmpty(data.googleSpotImage.spImgName)) {
				var imagePathName =
					gcpSpotPlaceNormalImgPath + data.googleSpotImage.spImgName;
				htmlString += '<img src="' + imagePathName + '" alt=""/>';
			} else {
				htmlString += '<img src="" alt=""/>';
			}
			htmlString += '</p>';
			htmlString += '<p class="google_store_name">' + data.spName + '</p>';
			htmlString += '<p class="google_store_etc"></p>';
			if (isEmpty(data.spDesc)) data.spDesc = '';
			htmlString += '<div class="google_store_info">' + data.spDesc + '</div>';
			htmlString += '<div class="google_store_part">';
			htmlString += '<ul>';
			htmlString += '<li><i class="xi-maker xi-x"></i>' + data.spAddress + '</li>';
			htmlString += '<li><i class="xi-clock-o xi-x"></i>';
			htmlString += '<p class="select_btn">';
			if (!isEmpty(data.spBizStatus) && data.spBizStatus === '운영중')
				htmlString +=
					'<span style="color: rgba(24,128,56,1.00);">' +
					data.spBizStatus +
					' ' +
					openTimeObj.todayOpenTime +
					'</span>';
			else
				htmlString +=
					'<span style="color: rgba(217,48,37,1.00);">' +
					data.spBizStatus +
					' ' +
					openTimeObj.todayOpenTime +
					'</span>';
			htmlString += '</p>';
			htmlString += '<p class="msg_info"></p>';
			htmlString += '<div class="select_view_info">';
			htmlString += '<table>';
			//			console.log(openTimeObj.daysArray);
			for (var idx in openTimeObj.daysArray) {
				htmlString += '<tr>';
				htmlString += '<th>' + openTimeObj.daysArray[idx][0] + '</th>';
				htmlString += '<td>' + openTimeObj.daysArray[idx][1] + '</td>';
				htmlString += '</tr>';
			}
			htmlString += '</table>';
			htmlString += '</div>';
			htmlString += '</li>';
			if (!isEmpty(data.spHomepage))
				htmlString +=
					'<li><i class="xi-globus xi-x"></i><a href="' +
					data.spHomepage +
					'" target="_blank">' +
					data.spHomepage +
					'</a></li>';
			else
				htmlString +=
					'<li><i class="xi-globus xi-x"></i><a href="#" target="_blank">-</a></li>';
			htmlString += '<li><i class="xi-call xi-x"></i>' + data.spPhone + '</li>';
			if (isEmpty(data.spRegionHashtag)) data.spRegionHashtag = '';
			if (isEmpty(data.spAreaHashtag)) data.spAreaHashtag = '';
			if (isEmpty(data.spThemeHashtag)) data.spThemeHashtag = '';
			htmlString +=
				'<li><i class="xi-tag xi-x"></i><em>' +
				data.spRegionHashtag +
				'</em><em>' +
				data.spAreaHashtag +
				'</em><em>' +
				data.spThemeHashtag +
				'</em></li>';
			htmlString += '</ul>';
			htmlString += '</div>';
			htmlString += '</div>';

			htmlString += '<div class="tabyoutube_item">';
			htmlString += '<div class="google_review_area">';
			htmlString += '<div>';
			htmlString += '<p class="tit">리뷰 요약</p>';
			//			console.log(data.spName + ' ' + data.spRating);
			htmlString += '<p class="total_star">' + data.spRating.toString() + '</p>';
			htmlString += '<p>';
			var rRating = 0;
			if (!isEmpty(data.spRating)) rRating = Math.round(data.spRating);

			for (var idx = 0; idx < 5; idx++) {
				if (idx < rRating) htmlString += '<img src="/images/ic_star.png"  alt=""/>';
				else htmlString += '<img src="/images/ic_star_empty.png" alt=""/>';
			}
			htmlString += '</p>';
			htmlString += '</div>';
			htmlString += '<p>리뷰 ' + data.spReviewCnt.toString() + '</p>';
			htmlString += '</div>';
			htmlString += '<div class="popup_review popup-scroll">';
			if (!isEmpty(data.googleSpotReviewList)) {
				var reviewList = data.googleSpotReviewList;
				for (var idx in reviewList) {
					var reviewObj = reviewList[idx];
					htmlString += '<div class="review_part">';
					htmlString += '<div class="review_part_top">';
					htmlString +=
						'<p><img alt="" jstcache="1586" src="' +
						reviewObj.spReviewerProfileUrl +
						'" width="30" height="30" class="NBa7we" jsan="7.NBa7we,8.src,0.alt"></p>';
					htmlString += '<div class="review_write_area">';
					if (isEmpty(reviewObj.spReviewerName)) reviewObj.spReviewerName = '';
					htmlString +=
						'<p class="review_writer">' + reviewObj.spReviewerName + '</p>';
					htmlString +=
						'<p><span>지역 가이드</span><span>· 리뷰 ' +
						reviewObj.spReviewerRating.toString() +
						'</span></p>';
					htmlString += '</div>';
					htmlString += '</div>';
					htmlString += '<div class="review_part_middle">';
					htmlString += '<p>';
					var userRating = 0;
					if (!isEmpty(reviewObj.spReviewerRating))
						userRating = Math.round(reviewObj.spReviewerRating);

					for (var idx = 0; idx < 5; idx++) {
						if (idx < userRating)
							htmlString += '<img src="/images/ic_star.png"  alt=""/>';
						else htmlString += '<img src="/images/ic_star_empty.png" alt=""/>';
					}
					htmlString += '</p>';
					if (isEmpty(reviewObj.spReviewerTimeDesc))
						reviewObj.spReviewerTimeDesc = '';
					htmlString += '<p>' + reviewObj.spReviewerTimeDesc + '</p>';
					htmlString += '</div>';
					htmlString += '<div class="reviewe_part_bottom">';
					if (isEmpty(reviewObj.spReviewerText)) reviewObj.spReviewerText = '';
					htmlString += reviewObj.spReviewerText;

					htmlString += '</div>';
					//					htmlString +=				'<div class="review_photo"><img src="https://lh5.googleusercontent.com/p/AF1QipP1eHAx4IU0OpnZQbsWvtInW_EmvcxNNbx5DaS1=w600-h450-p-k-no"></div>'
					htmlString += '</div>';
				}
			}
			htmlString += '</div>';
			htmlString += '</div>';
			htmlString += '</div>';
			htmlString += '</div>';
			spotPlaceTab2Div.innerHTML = htmlString;

			// 동적 html에서 필요한데 common.js에서 처리 못하여 이곳에서 재정의 함
			$('.select_btn').click(function () {
				$(this).toggleClass('down');
				$(this).parent().find('.select_view_info').toggleClass('down');
			});
		},
		makeBookmarkTab(data) {
			//재작성 필요
			var spotPlaceTab3Div = document.getElementById('tab3-bookmark');
			var htmlString = '';
			var isEnrolledBookmark = checkEnrolledBookMark(data);
			if (isEnrolledBookmark == true)
				htmlString +=
					'<button><a href="#"><i class="xi-bookmark xi-x check" style="color:#ffff00"></i></a></button>';
			else
				htmlString +=
					'<button><a href="#"><i class="xi-bookmark-o xi-x "></i></a></button>';
			spotPlaceTab3Div.innerHTML = htmlString;
		},
		procYoutubeUrl() {
			//재작성 필요
			var url = document.getElementById('youtubeUrl').value;
			var spId = this.savedGoogleSpotPlace.spId;
			$.ajax({
				url: '/api/regist/getGoogleSpotPlaceYoutubeBySpIdAndUrl',
				data: { spId: spId, url: url },
				type: 'POST',
				dataType: 'json',
				success: function (result) {
					if (result.resultCode > 0) {
						alert(result.message);
						getYoutubeDetailData(url);
					} else if (result.resultCode == -1) {
						alert(result.message);
					}
				},
				error: function (error) {
					alert('[procYoutubeUrl][error][' + error.responseText + ']');
				},
			});
		},
		getYoutubeDetailData(url) {
			//재작성 필요
			$.ajax({
				url: 'https://www.googleapis.com/youtube/v3/search',
				type: 'get',
				dataType: 'json',
				data: {
					part: 'snippet',
					key: apiKey,
					q: url,
					maxResults: 50,
					type: 'video',
					videoEmbeddable: 'true',
				},
				success: function (data) {
					//	      			console.log(data);
					$.each(data.items, function (i, item) {
						//       				console.log(item.snippet.thumbnails);
						if (item.id.videoId == url) {
							var addedSpotYoutubeInfo = {};
							addedSpotYoutubeInfo.url = url;
							addedSpotYoutubeInfo.title = item.snippet.title;
							addedSpotYoutubeInfo.dsc = item.snippet.description;
							addedSpotYoutubeInfo.youtubeMakeDat =
								item.snippet.publishedAt.replace(/[TZ]/gi, ' ');
							addedSpotYoutubeInfo.channelId = item.snippet.channelId;
							addedSpotYoutubeInfo.channelTitle = item.snippet.channelTitle;
							getYoutubeProfilePicture(
								item.snippet.channelId,
								addedSpotYoutubeInfo,
							);
							this.savedGoogleSpotPlace.addedSpotYoutubeInfo =
								addedSpotYoutubeInfo;
							makeSpotPlaceYoutubeTab(
								this.savedGoogleSpotPlace.googleSpotYoutubeList,
								this.savedGoogleSpotPlace.addedSpotYoutubeInfo,
								'youtube',
							);
							var height = $('.pop_registration_youtube').offset();
							$('#tab1').animate({ scrollTop: height.top }, 400);
						}
					});
				},
				error: function (error) {
					alert('[getYoutubeDetailData][error][' + error.responseText + ']');
				},
			});
		},
		getYoutubeProfilePicture(channelId, addedSpotYoutubeInfo) {
			const apiKey = 'YOUR_API_KEY'; // 본인의 YouTube API 키로 교체하세요.

			// YouTube API로 채널 정보 요청
			fetch(
				`https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${channelId}&fields=items%2Fsnippet%2Fthumbnails&key=${apiKey}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
					},
				},
			)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then((data) => {
					const items = data.items;

					if (items.length > 0) {
						// 프로필 이미지 URL을 추출하여 addedSpotYoutubeInfo에 저장
						const profileUrl = items[0].snippet.thumbnails.default.url;
						addedSpotYoutubeInfo.profileImageUrl = profileUrl;
					}
				})
				.catch((error) => {
					alert('[getYoutubeProfilePicture][error][' + error.message + ']');
				});
		},
		registerGoogleSpotPlaceYoutubeInfo() {
			if (
				!this.isEmpty(this.savedGoogleSpotPlace) &&
				!this.isEmpty(this.savedGoogleSpotPlace.addedSpotYoutubeInfo)
			) {
				this.savedGoogleSpotPlace.addedSpotYoutubeInfo.spId =
					this.savedGoogleSpotPlace.spId;
				const requestData = JSON.stringify(
					this.savedGoogleSpotPlace.addedSpotYoutubeInfo,
				);

				// 서버에 YouTube 정보 등록 요청
				fetch('/api/regist/insertGoogleSpotYoutube', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json; charset=UTF-8',
					},
					body: requestData,
				})
					.then((response) => {
						if (!response.ok) {
							throw new Error(`HTTP error! Status: ${response.status}`);
						}
						return response.json();
					})
					.then((result) => {
						if (!this.isEmpty(result)) {
							alert('장소 YouTube 동영상이 등록되었습니다.');
							this.savedGoogleSpotPlace.googleSpotYoutubeList.push(
								this.savedGoogleSpotPlace.addedSpotYoutubeInfo,
							);
							this.makeSpotPlaceYoutubeTab(
								this.savedGoogleSpotPlace.googleSpotYoutubeList,
								'',
								'youtube',
							);
						}
					})
					.catch((error) => {
						alert(
							'[registerGoogleSpotPlaceYoutubeInfo][error][' + error.message + ']',
						);
					});
			}
		},
		onBookmarkClick(spId) {
			const loginId = this.emailId;
			if (this.isEmpty(loginId) || loginId === 'anonymousUser') {
				this.checkAuthenticated();
				return;
			}
			const mySpotPlace = {
				loginId: loginId,
				spId: spId,
				areaId: this.myMetaStreetInfo.areaId,
				regionId: this.myMetaStreetInfo.regionId,
			};

			// 서버에 북마크 등록 요청
			fetch('/api/mypage/manageMySpotPlace2BookMark', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
				},
				body: JSON.stringify(mySpotPlace),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.text();
				})
				.then((result) => {
					alert(result);
				})
				.catch((error) => {
					alert('[onBookmarkClick][error][' + error.message + ']');
				});
		},
		checkEnrolledBookMark(googleSpotPlace) {
			if (
				!this.isEmpty(googleSpotPlace) &&
				!this.isEmpty(googleSpotPlace.myBookmarkSpotPlaceList)
			) {
				const myBookmarkSpotPlaceList = googleSpotPlace.myBookmarkSpotPlaceList;
				for (const MyBookmarkSpotPlace of myBookmarkSpotPlaceList) {
					if (MyBookmarkSpotPlace.spId === googleSpotPlace.spId) {
						return true;
					}
				}
			}
			return false;
		},
		getDistanceFromLatLng(lat1, lng1, lat2, lng2) {
			const R = 6371; // 지구 반경 (km)
			let latDif = this.deg2rad(lat2 - lat1);
			let lngDif = this.deg2rad(lng2 - lng1);

			lat1 = this.deg2rad(lat1);
			lat2 = this.deg2rad(lat2);

			let a =
				Math.sin(latDif / 2) * Math.sin(latDif / 2) +
				Math.cos(this.deg2rad(lat1)) *
					Math.cos(this.deg2rad(lat2)) *
					Math.sin(lngDif / 2) *
					Math.sin(lngDif / 2);
			let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			let d = R * c; // km 단위로 반환
			return d;
		},
		deg2rad(deg) {
			return deg * (Math.PI / 180);
		},
		square(x) {
			return Math.pow(x, 2);
		},
		round(num) {
			let m = Number((Math.abs(num) * 100).toPrecision(15));
			return (Math.round(m) / 100) * Math.sign(num);
		},
		isEmpty(value) {
			return value == null || value.length === 0;
		},
		getTodayLabel() {
			const week = [
				'일요일',
				'월요일',
				'화요일',
				'수요일',
				'목요일',
				'금요일',
				'토요일',
			];
			const today = new Date().getDay();
			const todayLabel = week[today];
			return todayLabel;
		},
		getTodayOpenTime(data) {
			const openTimeObj = {
				todayOpenTime: '',
				daysArray: [],
			};

			if (!this.isEmpty(data.spOpenTime)) {
				const daysArray = data.spOpenTime.split(',');

				const todayLabel = this.getTodayLabel();

				for (const dayStr of daysArray) {
					const dayArray = dayStr.split(':');
					openTimeObj.daysArray.push(dayArray);

					const dayToEnd = dayStr.substring(dayStr.indexOf(':') + 1);
					openTimeObj.daysArray[openTimeObj.daysArray.length - 1][1] = dayToEnd;

					if (dayArray[0] === todayLabel) {
						openTimeObj.todayOpenTime = dayToEnd;
					}
				}
			}

			return openTimeObj;
		},
		WinClose() {
			window.open('about:blank', '_self').close();
		},
		loadYoutubeScript() {
			/**
			 * Youtube API 로드
			 */
			if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') {
				const tag = document.createElement('script');
				tag.src = 'https://www.youtube.com/iframe_api?wmode=transparent';
				const firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				window.onYouTubeIframeAPIReady = () => {
					this.onYouTubePlayer();
				};
			}
		},
		onYouTubePlayer() {
			this.player = new YT.Player('ytPlayer', {
				height: '100%',
				width: '100%',
				videoId: this.youtubeAreaInfo.url,
				// autoplay: true,
				wmode: 'transparent',
				playerVars: {
					controls: '2',
					playsinline: '1',
				},
				events: {
					onReady: this.onPlayerReady,
					onStateChange: this.onPlayerStateChange,
					onPlaybackRateChange: this.onPlaybackRateChange,
				},
			});
		},
		getYoutubeUrl(youtubeUrl) {
			try {
				this.player.loadVideoById(youtubeUrl, 5, 'large');
			} catch (error) {
				// console.log(error);
			}
		},
		onPlayerError() {
			// 플레이어 오류 시 실행할 작업
		},
		onPlayerReady(event) {
			// console.log('onPlayerReady 실행');
			// console.log(this.isFirstPlayerReady);
			// 2022.10.30 추가(동영상 시작 시점 설정)
			if (this.isFirstPlayerReady === false) {
				const tempYoutubeAreaRouteBranchList =
					this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
				if (
					tempYoutubeAreaRouteBranchList[0].brId !==
					tempYoutubeAreaRouteBranchList[1].brId
				) {
					this.playYoutubeAfterTime(0);
				} else {
					this.playYoutubeAfterTime(tempYoutubeAreaRouteBranchList[1].elapsedTime);
				}
				this.isFirstPlayerReady = true;
			}
			if (this.gStartLat !== 0 && this.gStartLng !== 0) {
				if (!this.isEmpty(this.regionId)) {
					axios
						.get('/api/v1/getMaxYoutubeAreaInfoListPlusByRegionId', {
							params: {
								regionId: this.regionId,
							},
						})
						.then((response) => {
							if (response.data) {
								this.youtubeAreaInfoList = response.data;
								const foundItem = this.youtubeAreaInfoList.find((item) => {
									return item.areaId === this.youtubeAreaInfo.areaId;
								});

								if (foundItem) {
									this.youtubeAreaInfo = foundItem;
								}

								this.drawMap();
								this.readyAnimation();
								// this.getRelatedBranchPointList(this.youtubeAreaInfo.areaId);
								this.findYoutubePosition(this.gStartLat, this.gStartLng);
							}
						})
						.catch((error) => {
							// console.log(error);
							// 오류 처리 코드를 추가하세요
						});
				}
			}
		},
		onPlayerStateChange(event) {
			this.youtubeStatus.before = this.playerState;
			this.playerState =
				event.data == YT.PlayerState.ENDED
					? '종료됨'
					: event.data == YT.PlayerState.PLAYING
					? '재생 중'
					: event.data == YT.PlayerState.PAUSED
					? '일시중지 됨'
					: event.data == YT.PlayerState.BUFFERING
					? '버퍼링 중'
					: event.data == YT.PlayerState.CUED
					? '재생준비 완료됨'
					: event.data == -1
					? '시작되지 않음'
					: '예외';
			this.youtubeStatus.after = this.playerState;
			//console.log('onPlayerStateChange 실행: ' + this.playerState);
			// 2022.12.9 추가(동영상 시작 시점 설정)
			if (this.isFirstPlayerChange === false) {
				const tempYoutubeAreaRouteBranchList =
					this.youtubeAreaInfo.tempYoutubeAreaRouteBranchList;
				if (
					tempYoutubeAreaRouteBranchList[0].brId !==
					tempYoutubeAreaRouteBranchList[1].brId
				) {
					this.playYoutubeAfterTime(0);
				} else {
					this.playYoutubeAfterTime(tempYoutubeAreaRouteBranchList[1].elapsedTime);
				}
				this.isFirstPlayerChange = true;
			}
			// 재생여부를 통계로 쌓는다.
			this.collectPlayCount(event.data);
		},
		onPlaybackRateChange(event) {
			this.rateFactor = event.target.getPlaybackRate();
			this.resetStreetVisitorValue();
			//console.log('playing rate = ' + this.rateFactor);
		},
		playYoutube(isNormal) {
			// 플레이어 자동실행 (주의: 모바일에서는 자동실행되지 않음)
			try {
				this.player.mute(); // lake add - 모바일에서 자동실행 위해서 필요
				this.player.playVideo();
				if (isNormal === 1) {
					// console.log('<== playYoutube() 강제실행');
				}
			} catch (error) {
				alert('<== playYoutube() 오류');
				// console.log(error);
			}
		},
		pauseYoutube() {
			// console.log('<== pauseYoutube() 플레이멈춤');
			this.player.pauseVideo();
		},
		collectPlayCount(data) {
			if (data == YT.PlayerState.PLAYING && this.played == false) {
				// todo statistics
				this.played = true;
				//console.log('statistics');
			}
		},
		getCurrentTime() {
			return this.player.getCurrentTime();
		},
		playYoutubeAfterTime(startTime) {
			this.player.mute(); // lake add - mobile에서 자동 실행위해서 필요
			this.player.playVideo();
			this.player.seekTo(startTime, true); // 영상의 시간을 startTime 초로 이동시킵니다.
		},
		getDistanceFromLatLng(lat1, lng1, lat2, lng2) {
			const R = 6371; // 지구 반지름 (단위: km)
			const latDiff = this.deg2rad(lat2 - lat1);
			const lngDiff = this.deg2rad(lng2 - lng1);

			lat1 = this.deg2rad(lat1);
			lat2 = this.deg2rad(lat2);

			const a =
				Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
				Math.cos(lat1) *
					Math.cos(lat2) *
					Math.sin(lngDiff / 2) *
					Math.sin(lngDiff / 2);
			const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			const distance = R * c; // 거리 (단위: km)
			return distance;
		},
		deg2rad(deg) {
			return deg * (Math.PI / 180);
		},
		moveToLandMark(lat, lng) {
			this.procYoutubePosition(lat, lng);
		},
		showSpotDetail(spotPlace) {
			this.spotDetailData = spotPlace;
			this.isSpotDetailVisible = true;
			// console.log(this.spotDetailData);
		},
		closeSpotDetail() {
			this.isSpotDetailVisible = false;
		},
		toggleSlide() {
			this.showMap = !this.showMap; // 상태를 토글
		},
		viewMarker(spotPlace) {
			if (this.spotMarker) {
				toRaw(this.spotMarker).setMap(null); // 마커를 지도에서 제거
			}
			const markerImage = {
				url: '/images/marker/recom_spot_marker.png', // 이미지 URL을 여기에 입력
				scaledSize: new google.maps.Size(30, 40), // 이미지 크기 조절
			};

			this.spotMarker = new google.maps.Marker({
				position: { lat: Number(spotPlace.lat), lng: Number(spotPlace.lng) }, // 마커 위치 설정
				map: this.mapData.mapId, // 해당 지도에 마커 추가
				icon: markerImage, // 마커 이미지 설정
			});
			//console.log(spotPlace);
		},
		hiddenMarker() {
			if (this.spotMarker) {
				toRaw(this.spotMarker).setMap(null); // 마커를 지도에서 제거
			}
		},
	},
};
</script>

<style scoped>
.subject {
	overflow: Hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	grid-column-start: 1;
	grid-column-end: 21;
	width: 100%;
	font-size: 18px;
	font-weight: 600;
	color: #222;
	letter-spacing: -0.7px;
	margin-bottom: 10px;
}
.iconArea {
	position: relative;
}
.iconArea .date {
	font-size: 13px;
	font-weight: 600;
	color: #777;
	width: 65px;
	line-height: 30px;
	text-align: right;
}
.iconArea .youtubeChannel {
	display: flex;
	width: calc(100% - 65px);
}
.iconArea .youtubeChannel .youtube_thum {
	width: 30px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 10px;
	margin-bottom: 0rem;
}
.iconArea .youtubeChannel .youtube_thum > img {
	max-width: 30px;
	height: auto;
	padding: 0px;
}
.iconArea .youtubeChannel a {
	width: calc(100% - 35px);
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
	overflow: hidden;
	line-height: 30px;
}
.iconArea .youtubeChannel span {
	font-size: 15px;
}
.iconArea .functionIco1 i {
	color: #999;
	font-size: 1.7em;
	cursor: pointer;
	margin: 0 2px;
}
.iconArea .functionIco1 i[class*='check'] {
	color: rgb(51, 50, 88);
}
.videoDetail {
	height: 100%;
	/* border: 1px solid #e5e5e5; */
}
.videoDetail .map {
	position: relative;
	height: 85%;
}
.videoDetail .map .video {
	width: 100%;
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
}
.videoDetail .map .video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.videoDetail .textArea .subject {
	width: 100%;
	overflow: Hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 18px;
	font-weight: 600;
	color: #222;
	padding: 15px;
	margin-bottom: 0px;
	box-sizing: border-box;
}
.videoDetail .iconArea {
	padding: 0 15px 15px;
	position: relative;
}
.videoDetail .iconArea .date {
	margin-left: 10px;
	font-size: 13px;
	font-weight: 600;
	width: 80px;
	color: #777;
	text-align: right;
}
.videoDetail .iconArea .youtubeChannel {
	height: 30px;
	width: calc(100% - 80px);
}
.videoDetail .iconArea .youtubeChannel > img {
	max-width: 30px;
	height: auto;
	padding: 0px 5px 0px 5px;
}
.videoDetail .iconArea .youtubeChannel span {
	font-size: 1em;
	align-self: center;
}
.videoDetail .iconArea .functionIco1 {
	width: 100px;
	align-self: center;
}
.videoDetail .iconArea .functionIco1 > img {
	max-width: 30px;
	height: auto;
	padding: 0px 15px 0px 15px;
}
.detail_map {
	width: 100%;
	position: relative;
	height: 0;
	/* padding-bottom: 56.25%; */
}
.detail_map iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.store_list {
	/* border-top: 5px solid #e5e5e5; */
	overflow: auto;
	height: 350px;
}
.store_list::-webkit-scrollbar {
	width: 10px;
	background: #fff;
	border-left: solid 1px rgba(255, 255, 255, 0.1);
}
.store_list::-webkit-scrollbar-thumb {
	background: #113184;
	border: solid 2px #fff;
	border-radius: 5px;
}
.store_item {
	border-bottom: 1px solid #e5e5e5;
	padding: 10px;
}
.store_item p.img {
	margin: 0;
	width: 90px;
	height: 55px;
	display: block;
	overflow: hidden;
}
.store_item p.img img {
	width: 100%;
}
.store_item .store_info {
	width: calc(100% - 90px);
	padding: 0 0 0 10px;
	align-self: center;
}
.store_item .store_info p.sotre_name {
	margin-bottom: 5px;
	font-size: 14px;
	color: #222;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 1em;
	font-weight: 500;
}
.starList span {
	display: inline-block;
	margin: 0 2px;
}
.starList span img {
	width: 14px;
}
.store_item a {
	width: 35px;
	text-align: center;
}
.store_item a img {
	height: 27px;
}
.videoTag {
	padding: 10px;
}

.videoTag p {
	border: 1px solid #e5e5e5;
	padding: 3px 10px;
	font-size: 13px;
	border-radius: 30px;
	margin: 0;
	background-color: #eaeff5;
}
.p-2 {
	padding: 0.1rem !important;
}
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8); /* 불투명도 조절 */
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
