import { createApp } from 'vue';

import App from './App.vue';
import Router from './router';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/yeosu.css';
import './assets/css/bootstrap.css';

// GA
import VueGtag from 'vue-gtag-next';

const app = createApp(App);

app.use(VueGtag, {
	property: { id: 'G-3T706GZC8R' },
});

app.use(Router);
app.mount('#app');

// eslint-disable-next-line
export const loadedGoogleMapsAPI = new Promise((resolve, reject) => {
	const userLanguage = navigator.language.toLowerCase();
	let language = '';
	let region = '';
	if (userLanguage === 'ko-kr') {
		language = 'ko';
		region = 'KR';
	} else if (userLanguage === 'ja-jp') {
		language = 'ja';
		region = 'JP';
	} else {
		language = 'en';
		region = 'US';
	}
	window['GoogleMapsInit'] = resolve;

	let GMap = document.createElement('script');

	GMap.setAttribute(
		'src',
		`https://maps.googleapis.com/maps/api/js?key=AIzaSyCJIeAFQw_vCXs66ASniuadE4F191BA1Ag&callback=GoogleMapsInit&libraries=&v=weekly&language=${language}&region=${region}`,
	);

	document.body.appendChild(GMap);
});
