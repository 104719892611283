<template>
	<div class="tour_detail_area">
		<div class="tour_title">
			<h3>{{ spot.spName }}</h3>
			<button class="close" title="닫기">
				<i class="xi-close xi-x" @click="closeSpotDetail"></i>
			</button>
		</div>
		<div class="tour_detail_body">
			<div class="tour_place_area">
				<img
					:src="`https://storage.googleapis.com/allthestreet-images/spot_images/normal/${spot.googleSpotImage.filePath}`"
				/>
			</div>

			<!--업체정보-->
			<div class="place_info_area">
				<div class="google_store_part">
					<ul>
						<li v-if="spot.spAddress">
							<i class="xi-maker xi-x"></i>{{ spot.spAddress }}
						</li>
						<li v-if="todayTimeTable.timeRange">
							<i class="xi-clock-o xi-x"></i>
							<p class="select_btn" @click="toggleTimeRange">
								<span style="color: rgba(24, 128, 56, 1)">{{
									todayTimeTable.timeRange
								}}</span>
							</p>
							<!-- <p class="msg_info"></p> -->
							<div class="select_view_info">
								<div v-show="showTimeRange">
									<ul>
										<li v-for="dayTime in dayTimes" :key="dayTime.dayOfWeek">
											{{ dayTime.timeRange }}
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li v-if="spot.spHomepage">
							<i class="xi-globus xi-x"></i
							><a :href="spot.spHomepage" target="_blank">{{ spot.spHomepage }}</a>
						</li>
						<li v-if="spot.spPhone">
							<i class="xi-call xi-x"></i>{{ spot.spPhone }}
						</li>
						<li>
							<i class="xi-tag xi-x"></i><em>{{ spot.spAreaHashtag }}</em
							><em>{{ spot.spRegionHashtag }}</em>
						</li>
					</ul>
				</div>
			</div>
			<!--//업체정보-->
			<div
				v-for="youtube in spot.googleSpotYoutubeList"
				:key="youtube.spId"
				class="stVideoVisual"
			>
				<div class="video">
					<iframe
						id="leftYtPlayer"
						class="map"
						style=""
						frameborder="0"
						allowfullscreen="1"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						:title="youtube.title"
						width="100%"
						height="100%"
						:src="
							'https://www.youtube.com/embed/' +
							youtube.url +
							'?controls=2&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fallthestreet.com&amp;widgetid=2'
						"
					></iframe>
				</div>
				<div class="textArea">
					<div class="subject">
						{{ youtube.title }}
					</div>
					<div class="iconArea d-flex justify-content-between">
						<div>
							<div class="youtubeChannel">
								<p class="youtube_thum">
									<img
										:src="youtube.profileImageUrl || '/images/youtubeIco.png'"
										class="rounded-image"
										alt="Profile Image"
									/>
								</p>
								<a
									:href="'https://www.youtube.com/channel/' + youtube.channelId"
									target="_blank"
									><span>{{ youtube.channelTitle }}</span></a
								>
							</div>
							<div class="date">
								{{ youtube.youtubeMakeDat.substring(0, 10) }}
							</div>
						</div>
						<div class="sns_area"><i class="xi-share-alt-o xi-x"></i></div>
					</div>
				</div>
			</div>
			<!-- 후기 -->
			<div class="tour_detail_body">
				<div
					v-for="review in spot.googleSpotReviewList"
					:key="review.spReviewerId"
					class="review_part"
				>
					<!-- :src="review.spReviewerProfileUrl.split(',')[1]" -->
					<div class="review_part_top d-flex align-items-center">
						<p>
							<img
								alt=""
								jstcache="1586"
								:src="review.spReviewerProfileUrl.split(',')[0]"
								width="30"
								height="30"
								class="NBa7we"
								jsan="7.NBa7we,8.src,0.alt"
							/>
						</p>
						<div class="review_write_area">
							<p class="review_writer">{{ review.spReviewerName }}</p>
							<p>
								<span>지역 가이드</span
								><span>· 리뷰 {{ review.spReviewerRating }}</span>
							</p>
						</div>
					</div>
					<div class="review_part_middle d-flex align-items-center">
						<span>
							<i
								v-for="n in Math.floor(review.spReviewerRating)"
								:key="n"
								class="bi bi-star-fill text-warning"
								style="margin-left: 5px"
							></i>
							<i
								v-for="n in 5 - Math.ceil(review.spReviewerRating)"
								:key="n"
								class="bi bi-star text-warning"
								style="margin-left: 5px"
							></i>
						</span>
						&nbsp;
						<p>{{ review.spReviewerTimeDesc }}</p>
					</div>
					<div class="reviewe_part_bottom">
						{{ review.spReviewerText }}
					</div>
				</div>
			</div>
			<!--//후기 -->
			<!-- //youtube 정보 -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'SpotDetailPc',
	props: {
		spot: {
			type: Object,
			default() {
				return null;
			},
		},
	},
	emits: ['close'], // "close" 이벤트를 명시적으로 선언
	data() {
		return {
			timeString: this.spot.spOpenTime,
			dayTimes: [],
			todayTimeTable: '',
			showTimeRange: false,
		};
	},

	mounted() {
		this.dayTimes = this.parseTimeString(this.timeString);
		this.todayTime();
	},
	methods: {
		closeSpotDetail() {
			this.$emit('close'); // 부모 컴포넌트에 모달 닫기 이벤트 전달
		},
		todayTime() {
			const today = new Date().toLocaleDateString('ko-KR', { weekday: 'long' });
			const todayDayTime = this.dayTimes.find(
				(dayTime) => dayTime.dayOfWeek === today,
			);
			if (todayDayTime) {
				this.todayTimeTable = todayDayTime;
			} else {
				this.todayTimeTable = '일정이 없습니다.';
			}
		},
		parseTimeString(timeString) {
			// 문자열을 파싱하여 요일별 시간을 추출하는 함수
			const days = timeString.split(',');
			const timeArray = [];

			for (const day of days) {
				const dayOfWeek = day.trim().split(':');
				timeArray.push({
					dayOfWeek: dayOfWeek[0],
					timeRange: day,
				});
			}
			return timeArray;
		},
		toggleTimeRange() {
			this.showTimeRange = !this.showTimeRange;
		},
		formatDateString(dateString) {
			// dateString을 Date 객체로 변환
			const date = new Date(dateString);

			// 년/월/일 형식으로 변환
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 두 자리로 포맷
			const day = String(date.getDate()).padStart(2, '0'); // 일자를 두 자리로 포맷

			// 년/월/일 문자열 반환
			return `${year}-${month}-${day}`;
		},
	},
};
</script>

<style scoped>
body,
input,
textarea,
select,
button {
	font-family: 'Noto Sans KR', 'Malgun Gothic', 'Dotum', '돋움', 'Arial';
	line-height: 1.5em;
	color: #323232;
	letter-spacing: -0.05em;
}

.tour_detail_area {
	position: fixed;
	z-index: 99999;
	top: 5%;
	height: 100vh;
	width: 40vw;
	left: 30%;
	background-color: rgba(255, 255, 255, 1);
	overflow-y: auto;
}

.tour_title {
	position: relative;
	background: var(--bs-primary);
	padding: 0.8rem;
}
.tour_title h3 {
	color: var(--bs-white);
	font-size: 1.2rem;
	padding: 0;
	margin: 0;
}
.tour_title .close {
	background: rgba(0, 0, 0, 0);
	border: none;
	color: var(--bs-white);
	position: absolute;
	top: 0.8rem;
	right: 0.8rem;
}
.tour_detail_body {
	padding: 1rem;
}
.tour_place_area {
	overflow: hidden;
}
.tour_place_area img {
	width: 100%;
}
.google_store_part {
	padding: 10px 2%;
}
.google_store_part ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.google_store_part li {
	padding: 2px 0 2px 25px;
	position: relative;
	font-size: 0.875rem;
}
.google_store_part li i {
	position: absolute;
	top: 8px;
	left: 0;
	color: #1a73e8;
}
.google_store_part li p.select_btn {
	cursor: pointer;
	position: relative;
	margin-bottom: 0;
}
.google_store_part li p.select_btn:after {
	display: inline-block;
	content: '';
	font-family: xeicon;
	position: absolute;
	top: 0;
	margin-left: 15px;
}
.google_store_part .msg_info {
	color: #dd860e;
	display: block;
	font-size: 0.9em;
	padding-top: 10px;
	margin-bottom: 0;
	/* display: none; */
}
.google_store_part li .select_view_info {
	/* display: none; */
	/* margin-top: 10px; */
}
.google_store_part li .select_view_info li {
	width: 100%;
}
.google_store_part li .select_view_info table th {
	width: 20%;
	color: #000;
	font-weight: 400;
}
.google_store_part li .select_view_info li {
	padding: 3px 0;
	line-height: 1.4em;
}
.google_store_part li em {
	display: inline-block;
	border-radius: 30px;
	padding: 7px 10px;
	border: 1px solid #ddd;
	margin: 0 2px 2px 0;
	font-size: 0.8rem;
	font-style: normal;
	line-height: 1em;
}

.stVideoVisual {
	width: 100%;
	margin: 0;
	padding: 0 0 0.5rem 0;
	position: relative;
	border-bottom: 1px solid #d9d9d9;
}

.stVideoVisual textArea {
	width: 100%;
	padding: 14px;
	box-sizing: border-box;
}

.stVideoVisual .text-center {
	position: fixed;
	bottom: 8rem;
	left: 0;
	width: 100%;
	z-index: 10;
}
.subject {
	overflow: Hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	grid-column-start: 1;
	grid-column-end: 21;
	width: 100%;
	font-size: 0.95rem;
	font-weight: 600;
	color: #222;
	letter-spacing: -0.7px;
	margin: 0.3rem 0;
}
.iconArea {
	position: relative;
}
.iconArea .date {
	font-size: 0.75rem;
	color: #777;
	line-height: 20px;
}
.iconArea > div:not(.sns_area) {
	width: calc(100% - 35px);
}

.iconArea .sns_area {
	color: #777;
}
.iconArea .youtubeChannel {
	line-height: 1em;
}
.iconArea .youtubeChannel a {
	width: calc(100% - 35px);
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
	overflow: hidden;
	line-height: 20px;
}
.iconArea .youtubeChannel span {
	font-size: 0.85rem;
	color: #777;
}

.tour_detail_body .video {
	width: 100%;
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
}
.tour_detail_body .video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.review_part {
	border-bottom: 1px solid #eaeaea;
	padding: 0.6rem 0 0.6rem 0;
}
.review_part .review_part_top > p {
	width: 40px;
	margin-bottom: 0;
}
.review_part .review_part_top .review_write_area {
	width: calc(100% - 50px);
	margin-bottom: 0;
}
.review_part .review_part_top .review_write_area p {
	color: #70757a;
	font-size: 0.875rem;
	margin-bottom: 0;
	line-height: 1em;
}
.review_part .review_part_top .review_write_area p span {
	display: inline-block;
	margin-right: 10px;
}
.review_part .review_part_top .review_write_area p.review_writer {
	color: #000;
	font-size: 0.92em;
	margin-bottom: 5px;
	line-height: 1em;
}
.review_part .review_part_middle {
	padding: 0.4rem 0;
}
.review_part .review_part_middle p {
	margin-right: 0.3rem;
	color: #70757a;
	font-size: 0.875rem;
	margin-bottom: 0;
}
.review_part .review_part_middle p img {
	height: 14px;
	width: 14px;
}
.review_part .reviewe_part_bottom {
	color: #70757a;
	font-size: 0.875rem;
	line-height: 1.5em;
}
.review_part .review_photo img {
	max-width: 100%;
}

/* 닫기 버튼 스타일 */
.close-button {
	position: absolute;
	top: 30px; /* 오른쪽 위에서의 거리 조절 */
	right: 30px; /* 오른쪽에서의 거리 조절 */
	background-color: transparent;
	border: none;
	font-size: 20px;
	cursor: pointer;
	color: #000;
}
</style>
